<template>
    <div class="newOwnerContract">
<!--        <van-nav-bar class="nav-bar" fixed @click-left="leftReturn" :title="title" left-arrow></van-nav-bar>-->
<!--        <rxNavBar :title="title"></rxNavBar>-->
        <rx-nav-bar :title="title" ></rx-nav-bar>
        <!--上传图片loading-->
        <loading v-if="loadingFlag"></loading>
        <div class="part-inputpart part-inputpart-houseAddress">
            <div class="part-inputpart-row-1">
                <span :class=" houseAddress? 'content-have' : 'content-none' "></span>
                <span class="part-inputpart-row-header">房源地址</span>
                <span class="content-divide">|</span>
                <div class="nopadding">
                    <van-field type="text" v-model="houseAddress" class="addressInput" disabled/>
                </div>
            </div>
        </div>
        <div class="part-inputpart part-inputpart-houseAddress">
            <div class="part-inputpart-row-1">
                <span :class=" propertyRightAddress? 'content-have' : 'content-none' "></span>
                <span class="part-inputpart-row-header">产权地址</span>
                <span class="content-divide">|</span>
                <div class="nopadding">
                    <van-field type="text" v-model="propertyRightAddress" class="addressInput" placeholder="请输入产权地址" @input="propertyRightAddressd()"/>
                </div>
            </div>
        </div>
        <div class="headbar" :class="isFixed ? 'headbar-fixed' : '' ">
            <div @click="isTenantInfo">
                <span>业主信息</span>
                <div :class=" contractInfo.signedType &&
                    ownerInfo.phone &&
                    ownerInfo.name &&
                    ownerInfo.certificateCode &&
                    ownerInfo.emergencyContactName&&
                    ownerInfo.emergencyContactPhone &&
                    ownerInfo.receiver &&
                    contractInfo.bankType.value&&
                    ownerInfo.bankChanel &&
                    ownerInfo.bankBob &&
                    ownerInfo.bankCard &&
                    (!isChooseProxy || (isChooseProxy && proxyInfo.name && proxyInfo.phone && proxyInfo.certificateCode)) &&
                    (!isChooseCoOwner || (isChooseCoOwner && coOwner.name && coOwner.phone && coOwner.IdCard))
                    ? 'headbar-greentick':'headbar-graytick'">
                </div>
                <div v-if="0==whichInfo" class="headbar-redline"></div>
            </div>
            <div class="content-bigdivide"></div>
            <div @click="isContractInfo">
                <span>合同信息</span>
                <div :class="contractInfo.giveHouseTime  &&
                    contractInfo.startDecorateTime &&
                    contractInfo.endDecorateTime&&
                    contractInfo.noFeeBeginTime&&
                    contractInfo.noFeeEndTime &&
                    contractInfo.rent&&
                    contractInfo.leaseTerm&&
                    contractInfo.ownershipType.value &&
                    ownerInfo.rightNumber &&
                    ownerInfo.buildArea &&
                    contractInfo.toWhereType.value &&
                    contractInfo.signatory.value &&
                    (!contractInfo.partner || (contractInfo.partner && contractInfo.cooperationType))
                    ? 'headbar-greentick' : 'headbar-graytick' ">
                </div>
                <div v-if="1==whichInfo" class="headbar-redline"></div>
            </div>
        </div>
        <!--        业主信息-->
        <div v-if="0==whichInfo" :class="isFixed ? 'contentpart' : '' ">
            <div class="part">
                <span class="part-subtitle">出租人</span>
                <div class="part-inputpart">
                    <!--添加页面start-->
                    <!--签约角色类型-->
                    <div id="controllOwnerTypeSelect" class="part-inputpart-row" @click="showOrHiddenOfOwnerType">
                        <span :class=" contractInfo.signedType ? 'content-have' : 'content-none' "></span>
                        <span class="part-inputpart-row-header">签约人角色</span>
                        <span class="content-divide">|</span>
                        <span :class="flagType == '1' ? 'part-inputpart-row-noChoice': 'part-inputpart-row-normaltext' ">{{contractInfo.signedType.label}}</span>
                        <img class="part-inputpart-row-right"
                            :class=" isOwnerTypeSelect ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
                            src="../../../assets/images/triangle.png" />
                    </div>
                    <!--签约角色下拉选-->
                    <van-popup v-model="isOwnerTypeSelect" position="bottom" :lock-scroll="false">
                        <van-picker show-toolbar value-key="label" :columns="ownerTypeArr"
                            @cancel="isOwnerTypeSelect = false" @confirm="selectOwnerType" />
                    </van-popup>
                    <!--签约类型-->
                    <div class="part-inputpart-row">
                        <span class="part-inputpart-row-enablenon part-inputpart-row-header">签约类型</span>
                        <span class="content-divide">|</span>
                        <span :class=" isOnlineSign ? 'content-have' : 'content-none'"></span>
                        <span class="part-inputpart-row-normaltext" @click="chooseOnline">线上签约</span>
                        <span :class=" isUplineSign ? 'content-have' : 'content-none'"></span>
                        <span class="part-inputpart-row-normaltext" @click="chooseUpline">线下签约</span>
                    </div>

                    <div class="part-inputpart-row">
                        <span :class="ownerInfo.phone ? 'content-have' : 'content-none' "></span>
                        <span class="part-inputpart-row-header">手机号</span>
                        <span class="content-divide">|</span>
                        <div class="nopadding">
                            <van-field class="nopadding" :formatter="formatter" type="tel" v-model="ownerInfo.phone" oninput="value=value.replace(/[^\d]/g,'')"
                                       :disabled="isOwnerFlag" placeholder="填写方便联系的电话" maxlength="11"
                                       @blur="animatePhone" />
                        </div>
                    </div>
                    <div>
                        <div class="part-inputpart-row part-inputpart-row-short" style="width: 60%">
                            <div :class="ownerInfo.name? 'content-have' : 'content-none'"></div>
                            <span class="part-inputpart-row-header">姓名</span>
                            <span class="content-divide">|</span>
                            <div class="nopadding">
                                <van-field class="nopadding" :formatter="formatter" type="text" v-model="ownerInfo.name" :disabled="ownerInfo.nameState" placeholder="业主姓名"
                                           @blur="checkName('ownerInfo',ownerInfo.name)" />
                            </div>
                        </div>
                        <div class="part-inputpart-row-sex">
                            <van-radio-group class="radio_group" direction="horizontal" v-model="ownerInfo.sex"
                            :disabled=" ownerInfo.certificateType.value == '0'|| ownerInfo.sexState || Object.keys(ownerInfo.certificateType).length == 0"
                            >
                                <van-radio name="1">
                                    <template #icon="props">
                                        <span :class="props.checked ? 'isChecked radio_span':'radio_span'">男</span>
                                    </template>
                                </van-radio>
                                <span class="content-divide  part-inputpart-row-short-graytext">|</span>
                                <van-radio name="0">
                                    <template #icon="props">
                                        <span :class="props.checked ? 'isChecked radio_span':'radio_span'">女</span>
                                    </template>
                                </van-radio>
                            </van-radio-group>
                        </div>
                    </div>
                    <div id="ownerCardPanel" class="part-inputpart-row">
                        <span :class="ownerInfo.certificateCode ? 'content-have' : 'content-none' "></span>
                        <span v-if="Object.keys(ownerInfo.certificateType).length == 0"
                            class="part-inputpart-row-header"
                            @click="clickCardShow">{{certificateTypeArr[0].label}}</span>
                        <span v-else class="part-inputpart-row-header"
                            @click="clickCardShow">{{ownerInfo.certificateType.label}}</span>
                        <img :class=" isCardShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
                            src="../../../assets/images/triangle.png" />
                        <span class="content-divide">|</span>
                        <div class="nopadding">
                            <van-field class="nopadding" :formatter="formatter" v-model="ownerInfo.certificateCode" type="text" placeholder="填写证件号码"
                            :disabled="ownerInfo.certificateState" @blur="animateOwnerIdCard" />
                        </div>
                    </div>
                    <div>
                        <van-popup v-model="isCardShow" position="bottom">
                            <van-picker show-toolbar value-key="label" :columns="certificateTypeArr"
                                @cancel="isCardShow = false" @confirm="selectCardOption" />
                        </van-popup>
                    </div>
                    <div class="part-inputpart-row">
                        <div
                            :class="ownerInfo.emergencyContactName? 'content-have' : 'content-none'">
                        </div>
                        <span class="part-inputpart-row-header">紧急联系人</span>
                        <span class="content-divide">|</span>
                        <div class="nopadding">
                            <van-field class="nopadding" :formatter="formatter"  type="text" v-model="ownerInfo.emergencyContactName" placeholder="紧急联系人姓名"
                                       @blur="checkName('emergencyContactName',ownerInfo.emergencyContactName)" />
                        </div>
                    </div>
                    <div class="part-inputpart-row">
                        <span
                            :class=" 0==ownerInfo.emergencyContactPhone ? 'content-none' : 'content-have' "></span>
                        <span class="part-inputpart-row-header">紧急联系电话</span>
                        <span class="content-divide">|</span>
                        <div class="nopadding">
                            <van-field class="nopadding" :formatter="formatter" type="text" v-model="ownerInfo.emergencyContactPhone"
                                       maxlength="11" placeholder="填写方便联系的电话"
                                       @blur="animateEmPhone" />
                        </div>
                    </div>
                    <!--邮箱信息-->
                    <div class="part-inputpart-row">
                        <span class="part-inputpart-row-enablenon part-inputpart-row-header">邮箱</span>
                        <span class="content-divide">|</span>
                        <div class="nopadding">
                            <van-field class="nopadding" :formatter="formatter" type="text" v-model="ownerInfo.email" placeholder="填写联系邮箱" @blur="animateEmail" />
                        </div>
                    </div>
                    <!--紧急联系地址信息-->
                    <div class="part-inputpart-row">
                        <span class="part-inputpart-row-enablenon part-inputpart-row-header">紧急联系地址</span>
                        <span class="content-divide">|</span>
                        <div class="nopadding">
                            <van-field class="nopadding" :formatter="formatter" type="text" v-model="ownerInfo.emergencyAddress" placeholder="填写紧急联系地址" />
                        </div>
                    </div>
                    <div class="part-inputpart-cardpic">
                        <div class="part-inputpart-row part-inputpart-cardpic-row">
                            <span class="part-inputpart-row-enablenon part-inputpart-row-header">证件凭证</span>
                        </div>
                        <div class="part-inputpart-cardpic-module">
                            <div class="part-cardpic-module">
                                <div  v-show="ownerInfo.yzFrontOfCard!=''" class="part-inputpart-cardpic-pic">
                                <img v-show="ownerInfo.yzFrontOfCard!=''"
                                    :src="ownerInfo.yzFrontOfCard" @click="changUpload('yzFrontOfCard')"/>
                                </div>
                                <van-uploader v-show="ownerInfo.yzFrontOfCard==''" :preview-image="false" ref="yzFrontOfCard"
                                    :after-read="afterReadCertificateFront" upload-icon="plus" :max-count="1">
                                    <div class="part-inputpart-cardpic-button"></div>
                                </van-uploader>

                                <div class="part-inputpart-cardpic-text">
                                    {{!isCardSelected? certificateTypeArr[0].label :ownerInfo.certificateType.label}}正面
                                </div>
                            </div>
                            <div class="part-cardpic-module">
                                <div v-show="ownerInfo.yzReverseOfCard!=''" class="part-inputpart-cardpic-pic">
                                <img v-show="ownerInfo.yzReverseOfCard!=''"
                                    :src="ownerInfo.yzReverseOfCard"  @click="changUpload('yzReverseOfCard')"/>
                                </div>
                                <van-uploader v-show="ownerInfo.yzReverseOfCard==''" :preview-image="false" ref="yzReverseOfCard"
                                              image-fit="contain"
                                    :after-read="afterReadCertificateBack" upload-icon="plus">
                                    <div class="part-inputpart-cardpic-button"></div>
                                </van-uploader>
                                <div class="part-inputpart-cardpic-text">
                                    {{!isCardSelected ? certificateTypeArr[0].label :ownerInfo.certificateType.label}}反面
                                </div>
                            </div>
                            <div class="part-cardpic-module">
                                <div v-show="ownerInfo.yzHoldCard" class="part-inputpart-cardpic-pic">
                                <img v-show="ownerInfo.yzHoldCard"
                                    :src="ownerInfo.yzHoldCard"  @click="changUpload('yzHoldCard')"/>
                                </div>
                                <van-uploader v-show="!ownerInfo.yzHoldCard" :preview-image="false" ref="yzHoldCard"
                                              image-fit="contain"
                                              :after-read="afterReadCertificateHandHeld" upload-icon="plus" :max-count="1">
                                    <div class="part-inputpart-cardpic-button"></div>
                                </van-uploader>
                                <div class="part-inputpart-cardpic-text">
                                    手持{{!isCardSelected? certificateTypeArr[0].label :ownerInfo.certificateType.label}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="part">
                <span class="part-subtitle">收款信息</span>
                <!--收款人-->
                <div class="part-inputpart">
                    <div class="part-inputpart-row">
                        <span :class=" ownerInfo.receiver ? 'content-have' : 'content-none' "></span>
                        <span class="part-inputpart-row-header">收款人姓名</span>
                        <span class="content-divide">|</span>
                        <div class="nopadding">
                            <van-field class="nopadding" :formatter="formatter" type="text" v-model="ownerInfo.receiver" placeholder="请输入收款人"
                                       @blur="checkName('receiver',ownerInfo.receiver)" />
                        </div>
                    </div>
                    <!--银行卡号-->
                    <div class="part-inputpart-row">
                        <span :class=" ownerInfo.bankCard? 'content-have' : 'content-none' "></span>
                        <span class="part-inputpart-row-header" v-if="Object.keys(bankTypeInfo).length == 0" @click="bankTypeChoose=true">银行卡</span>
                        <span class="part-inputpart-row-header" v-else @click="bankTypeChoose=true">{{bankTypeInfo.label}}</span>

                        <img :class=" bankTypeChoose ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
                             src="../../../assets/images/triangle.png" />
                        <span class="content-divide">|</span>
                        <div class="nopadding">
                            <van-field class="nopadding" format-trigger="onBlur" :formatter="formatter" type="number" v-model="ownerInfo.bankCard" placeholder="填写银行卡号" @blur="chickBankCard" />
                        </div>
                    </div>
                    <!--储蓄类型选择-->
                    <div>
                        <van-popup v-model="bankTypeChoose" position="bottom">
                            <van-picker show-toolbar value-key="label" :columns="savingsCertificateList"
                                        @cancel="bankTypeChoose = false" @confirm="chooseBankType" />
                        </van-popup>
                    </div>
                    <!--所属银行信息-->
                    <div id="bankTypeSelect" class="part-inputpart-row" @click="showOrHiddenOfBankType">
                        <span :class=" contractInfo.bankType.label ? 'content-have' : 'content-none' "></span>
                        <span class="part-inputpart-row-header">所属银行</span>
                        <span class="content-divide">|</span>
                        <span class="part-inputpart-row-graytext"
                            :class="contractInfo.bankType.label ? 'part-inputpart-row-normaltext':''">{{contractInfo.bankType.label||contractInfo.bankType}}</span>
                        <img class="part-inputpart-row-right"
                            :class=" isBankTypeSelect ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
                            src="../../../assets/images/triangle.png" />
                    </div>
                    <!--所属银行下拉选-->
                    <div>
                        <van-popup v-model="isBankTypeSelect" position="bottom">
                            <van-picker show-toolbar value-key="label" :columns="bankTypeArr"
                                @cancel="isBankTypeSelect = false" @confirm="selectBankType" />
                        </van-popup>
                    </div>
                    <!--省份-->
                    <div id="bankTypeSelect" class="part-inputpart-row" @click="showOrHiddenOfProvince">
                        <span :class=" contractInfo.province.name ? 'content-have' : 'content-none' "></span>
                        <span class="part-inputpart-row-header">省份</span>
                        <span class="content-divide">|</span>
                        <span class="part-inputpart-row-graytext"
                            :class="contractInfo.province.name ? 'part-inputpart-row-normaltext':''">{{contractInfo.province.name}}</span>
                        <img class="part-inputpart-row-right"
                            :class=" isProvinceSelect ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
                            src="../../../assets/images/triangle.png" />
                    </div>
                    <!--省份下拉-->
                    <div>
                        <van-popup v-model="isProvinceSelect" position="bottom">
                            <van-picker show-toolbar value-key="name" :columns="provinceArr"
                                @cancel="isProvinceSelect = false" @confirm="selectProvince" />
                        </van-popup>
                    </div>
                    <!--城市-->
                    <div id="bankTypeSelect" class="part-inputpart-row" @click="showOrHiddenOfCity">
                        <span :class=" contractInfo.city.name ? 'content-have' : 'content-none' "></span>
                        <span class="part-inputpart-row-header">城市</span>
                        <span class="content-divide">|</span>
                        <span class="part-inputpart-row-graytext"
                            :class="contractInfo.city.name ? 'part-inputpart-row-normaltext':''">{{contractInfo.city.name}}</span>
                        <img class="part-inputpart-row-right"
                            :class=" isCitySelect ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
                            src="../../../assets/images/triangle.png" />
                    </div>
                    <!--城市下拉选-->
                    <div>
                        <van-popup v-model="isCitySelect" position="bottom">
                            <van-picker show-toolbar value-key="name" :columns="cityArr"
                                @cancel="isCitySelect = false" @confirm="selectCity" />
                        </van-popup>
                    </div>
                    <!--所属支行-->
                    <div class="part-inputpart-row">
                        <span :class=" ownerInfo.bankChanel? 'content-have' : 'content-none' "></span>
                        <span class="part-inputpart-row-header">所属支行</span>
                        <span class="content-divide">|</span>
                        <div class="nopadding">
                            <van-field class="nopadding" :formatter="formatter" type="text" @click="selectSubBankList" v-model="ownerInfo.bankChanel" placeholder="填写所属支行" />
                        </div>
                    </div>

                    <!--行号-->
                    <div class="part-inputpart-row">
                        <span :class=" ownerInfo.bankBob ? 'content-have' : 'content-none' "></span>
                        <span class="part-inputpart-row-header">行号</span>
                        <span class="content-divide">|</span>
                        <div class="nopadding">
                            <van-field class="nopadding" :formatter="formatter" type="text" :disabled="!notFindSubBank" v-model="ownerInfo.bankBob" placeholder="填写行号" />
                        </div>
                    </div>
                     <div style="color:#ff5d3b;padding: 3px;margin: 0.4rem 10px 0 10px;" @click="toFindSubBank">{{warnSubBank}}</div>
                    <div class="part-inputpart-cardpic">
                        <div class="part-inputpart-cardpic-module-2">
                            <div class="">
                                <div class="part-inputpart-cardpic-pic"  v-show="ownerInfo.bankCardFront">
                                <img v-show="ownerInfo.bankCardFront"
                                    :src="ownerInfo.bankCardFront" @click="changUpload('bankCardFront')"/>
                                </div>
                                <van-uploader v-show="!ownerInfo.bankCardFront" :preview-image="false" ref="bankCardFront"
                                              image-fit="contain"
                                    :after-read="afterReadBankCardFront" upload-icon="plus" :max-count="1">
                                    <div class="part-inputpart-cardpic-button"></div>
                                </van-uploader>
                                <div class="part-inputpart-cardpic-text" style="padding-left: 0.75rem">银行卡正面</div>
                            </div>
                            <div class="">
                                <div class="part-inputpart-cardpic-pic" v-show="ownerInfo.bankCardReverse">
                                <img v-show="ownerInfo.bankCardReverse"
                                    :src="ownerInfo.bankCardReverse"  @click="changUpload('bankCardReverse')" />
                                </div>
                                <van-uploader v-show="!ownerInfo.bankCardReverse" :preview-image="false" ref="bankCardReverse"
                                              image-fit="contain"
                                    :after-read="afterReadBankCardBack" upload-icon="plus" :max-count="1">
                                    <div class="part-inputpart-cardpic-button"></div>
                                </van-uploader>
                                <div class="part-inputpart-cardpic-text" style="padding-left: 0.75rem">银行卡反面</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 代理人信息 -->
                <div class="part-inputpart-row"  style="font-size: 0.4rem">
                    <div class="part-inputpart-row-header" style="margin-left: 0.6rem">代理人信息</div>
<!--                    <div class="select-icon-blue"-->
<!--                        :class="{'blue-Active':contractInfo.signedType.value== '1' || isChooseProxy }"></div>-->
                    <van-radio-group class="radio_group" direction="horizontal" v-model="proxyRadio" style="margin-left: 2.7rem" >
                        <van-radio name="1">
                            <template #icon="props">
                                <span @click="isChoosePeoxyPerson(1)" :class="props.checked ? 'isChecked radio_span':'radio_span'">是</span>
                            </template>
                        </van-radio>
                        <span class="content-divide  part-inputpart-row-short-graytext">|</span>
                        <van-radio name="0">
                            <template #icon="props">
                                <span @click="isChoosePeoxyPerson(0)" :class="props.checked ? 'isChecked radio_span':'radio_span'">否</span>
                            </template>
                        </van-radio>
                    </van-radio-group>
                </div>
                <div class="part-inputpart" v-if="proxyRadio == '1'">
                    <!--姓名-->
                    <div class="part-inputpart-row">
                        <span :class=" proxyInfo.name ? 'content-have' : 'content-none' "></span>
                        <span class="part-inputpart-row-header">姓名</span>
                        <span class="content-divide">|</span>
                        <div class="nopadding">
                            <van-field class="nopadding" :formatter="formatter" type="text" v-model="proxyInfo.name" :disabled="proxyInfo.agentNameState"
                                       placeholder="请输入姓名" @blur="checkName('proxyInfo',proxyInfo.name)" />
                        </div>
                    </div>
                    <!--手机号-->
                    <div class="part-inputpart-row">
                        <span :class=" proxyInfo.phone ? 'content-have' : 'content-none' "></span>
                        <span class="part-inputpart-row-header">手机号</span>
                        <span class="content-divide">|</span>
                        <div class="nopadding">
                            <van-field class="nopadding" :formatter="formatter" type="text" v-model="proxyInfo.phone" :disabled="!isOwnerFlag"  oninput="value=value.replace(/[^\d]/g,'')"
                                       maxlength="11" placeholder="请输入手机号" @blur="animatePoPhone" />
                        </div>
                    </div>
                    <!--身份证-->
                    <div class="part-inputpart-row">
                        <span
                            :class=" proxyInfo.certificateCode? 'content-have' : 'content-none' "></span>
                        <span v-if="Object.keys(proxyInfo.certificateType).length == 0"
                            class="part-inputpart-row-header"
                            @click="clickProxyCardShow">{{certificateTypeArr[0].label}}</span>
                        <span v-else class="part-inputpart-row-header"
                            @click="clickProxyCardShow">{{proxyInfo.certificateType.label}}</span>
                        <img :class=" isProxyCardShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
                            src="../../../assets/images/triangle.png" />
                        <span class="content-divide">|</span>
                        <div class="nopadding">
                            <van-field class="nopadding" :formatter="formatter" :disabled="proxyInfo.agentCertificateState" v-model="proxyInfo.certificateCode"
                                       maxlength="18" @blur="animateProxyIdCard" />
                        </div>
                    </div>
                    <div>
                        <van-popup v-model="isProxyCardShow" position="bottom">
                            <van-picker show-toolbar value-key="label" :columns="certificateTypeArr"
                                @cancel="isProxyCardShow = false" @confirm="selectProxyCard" />
                        </van-popup>
                    </div>
                    <!--代理人证件信息-->
                    <div class="part-inputpart-cardpic">
                        <div class="part-inputpart-cardpic-module-1">
                            <div class="part-cardpic-module">
                                <img v-show="proxyInfo.wtrFrontOfCard" class="part-inputpart-cardpic-pic"
                                    :src="proxyInfo.wtrFrontOfCard" @click="changUpload('wtrFrontOfCard')"/>
                                <van-uploader v-show="!proxyInfo.wtrFrontOfCard" :preview-image="false" ref="wtrFrontOfCard"
                                              image-fit="contain"
                                    :after-read="afterReadProxyCertificateFront" upload-icon="plus" :max-count="1">
                                    <div class="part-inputpart-cardpic-button"></div>

                                </van-uploader>
                                <div class="part-inputpart-cardpic-text">身份证正面</div>
                            </div>
                            <div class="part-cardpic-module">
                                <div v-show="proxyInfo.wtrReverseOfCard" class="part-inputpart-cardpic-pic">
                                <img v-show="proxyInfo.wtrReverseOfCard"
                                    :src="proxyInfo.wtrReverseOfCard"  @click="changUpload('wtrReverseOfCard')"/>
                                </div>
                                <van-uploader v-show="!proxyInfo.wtrReverseOfCard" :preview-image="false" ref="wtrReverseOfCard"
                                              image-fit="contain"
                                    :after-read="afterReadProxyCertificateBack" upload-icon="plus">
                                    <div class="part-inputpart-cardpic-button"></div>
                                </van-uploader>
                                <div class="part-inputpart-cardpic-text">身份证反面</div>
                            </div>
                            <div class="part-cardpic-module">
                                <div v-show="proxyInfo.entrustment" class="part-inputpart-cardpic-pic">
                                <img v-show="proxyInfo.entrustment"
                                    :src="proxyInfo.entrustment"  @click="changUpload('entrustment')"/>
                                </div>
                                <van-uploader v-show="!proxyInfo.entrustment" :preview-image="false" ref="entrustment"
                                              image-fit="contain"
                                    :after-read="afterReadownerEntrust" upload-icon="plus" :max-count="1">
                                    <div class="part-inputpart-cardpic-button"></div>
                                </van-uploader>
                                <div class="part-inputpart-cardpic-text">业主委托书</div>
                            </div>
                            <div class="part-cardpic-module">
                                <div v-show="proxyInfo.holdEntrustment" class="part-inputpart-cardpic-pic">
                                <img v-show="proxyInfo.holdEntrustment"
                                    :src="proxyInfo.holdEntrustment" @click="changUpload('holdEntrustment')" />
                                </div>
                                <van-uploader v-show="!proxyInfo.holdEntrustment" :preview-image="false" ref="holdEntrustment"
                                              image-fit="contain"
                                    :after-read="afterReadownerEntrustHandle" upload-icon="plus" :max-count="1">
                                    <div class="part-inputpart-cardpic-button"></div>
                                </van-uploader>
                                <div class="part-inputpart-cardpic-text">业主手持委托书</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 共有权人信息 -->
                <div class="part-inputpart-row" style="font-size: 0.4rem">
                    <div class="part-inputpart-row-header" style="margin-left: 0.6rem">共有权人信息</div>
                <!-- <div class="select-icon-blue" :class="{'blue-Active':isChooseCoOwner}"></div>-->
                    <van-radio-group class="radio_group" direction="horizontal" v-model="coOwnerRadio" style="margin-left: 2.3rem" >
                        <van-radio name="1">
                            <template #icon="props">
                                <span @click="showOrHiddenCoOwnerInfo(1)" :class="props.checked ? 'isChecked radio_span':'radio_span'">是</span>
                            </template>
                        </van-radio>
                        <span class="content-divide  part-inputpart-row-short-graytext">|</span>
                        <van-radio name="0">
                            <template #icon="props">
                                <span @click="showOrHiddenCoOwnerInfo(0)" :class="props.checked ? 'isChecked radio_span':'radio_span'">否</span>
                            </template>
                        </van-radio>
                    </van-radio-group>
                </div>
                <div class="part-inputpart" v-if="isChooseCoOwner">
                    <!--姓名-->
                    <div class="part-inputpart-row">
                        <span :class=" coOwner.name? 'content-have' : 'content-none' "></span>
                        <span class="part-inputpart-row-header">姓名</span>
                        <span class="content-divide">|</span>
                        <div class="nopadding">
                            <van-field class="nopadding" :formatter="formatter" type="text" v-model="coOwner.name" placeholder="请输入姓名" @blur="checkName('coOwner',coOwner.name)" />
                        </div>
                    </div>
                    <!--手机号-->
                    <div class="part-inputpart-row">
                        <span :class=" coOwner.phone ? 'content-have' : 'content-none' "></span>
                        <span class="part-inputpart-row-header">手机号</span>
                        <span class="content-divide">|</span>
                        <div class="nopadding">
                            <van-field class="nopadding" :formatter="formatter" type="number" v-model="coOwner.phone" oninput="value=value.replace(/[^\d]/g,'')"
                                       maxlength="11" placeholder="请输入手机号" @blur="animateCoPhone" />
                        </div>
                    </div>
                    <!--身份证-->
                    <div class="part-inputpart-row">
                        <span :class=" coOwner.IdCard ? 'content-have' : 'content-none' "></span>
                        <span v-if="Object.keys(coOwner.certificateType).length == 0" class="part-inputpart-row-header"
                            @click="clickCoOwnerCardShow">{{certificateTypeArr[0].label}}</span>
                        <span v-else class="part-inputpart-row-header"
                            @click="clickCoOwnerCardShow">{{coOwner.certificateType.label}}</span>
                        <img :class=" isCoOwnerCardShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
                            src="../../../assets/images/triangle.png" />
                        <span class="content-divide">|</span>
                        <div class="nopadding">
                            <van-field class="nopadding" :formatter="formatter" type="text" v-model="coOwner.IdCard" oninput="value= value.replace(/[^\d|xX]/g,'')"
                                       maxlength="18" placeholder="请输入身份证号" @blur="animateCoIdCard" />
                        </div>
                    </div>
                    <div>
                        <van-popup v-model="isCoOwnerCardShow" position="bottom">
                            <van-picker show-toolbar value-key="label" :columns="certificateTypeArr"
                                @cancel="isCoOwnerCardShow = false" @confirm="selectCoOwnerCard" />
                        </van-popup>
                    </div>
                    <!--共有权人证件信息-->
                    <div class="part-inputpart-cardpic">
                        <div class="part-inputpart-cardpic-module-1">
                            <div class="part-cardpic-module">
                                <div v-show="coOwner.commonOwnerFrontOfCard" class="part-inputpart-cardpic-pic">
                                <img v-show="coOwner.commonOwnerFrontOfCard"
                                    :src="coOwner.commonOwnerFrontOfCard" @click="changUpload('commonOwnerFrontOfCard')"/>
                                </div>
                                <van-uploader v-show="!coOwner.commonOwnerFrontOfCard" :preview-image="false" ref="commonOwnerFrontOfCard"
                                              image-fit="contain"
                                    :after-read="afterReadCoOwnerCertificateFront" upload-icon="plus" :max-count="1">
                                    <div class="part-inputpart-cardpic-button"></div>
                                </van-uploader>
                                <div class="part-inputpart-cardpic-text">身份证正面</div>
                            </div>
                            <div class="part-cardpic-module">
                                <div class="part-inputpart-cardpic-pic" v-show="coOwner.commonOwnerReverseOfCard">
                                <img v-show="coOwner.commonOwnerReverseOfCard"
                                    :src="coOwner.commonOwnerReverseOfCard" @click="changUpload('commonOwnerReverseOfCard')"/>
                                </div>
                                <van-uploader v-show="!coOwner.commonOwnerReverseOfCard" :preview-image="false" ref="commonOwnerReverseOfCard"
                                              image-fit="contain"
                                    :after-read="afterReadCoOwnerCertificateBack" upload-icon="plus">
                                    <div class="part-inputpart-cardpic-button"></div>
                                </van-uploader>
                                <div class="part-inputpart-cardpic-text">身份证反面</div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        <!--        合同信息-->
        <div v-if="1==whichInfo" :class="isFixed ? 'contentpart' : '' ">
            <div class="part">
                <span class="part-subtitle">合同信息</span>
                <div class="part-inputpart">
                    <div class="part-inputpart-row">
                        <span class="part-inputpart-row-enablenon part-inputpart-row-redtext part-inputpart-row-header">
                            编号：
                            {{flagType!=2?contractInfo.number:contractInfo.newNumber}}
                        </span>
                        <div class="part-inputpart-row-right redsmallLabel">{{contractInfo.type}}</div>
                    </div>
                    <!--交房日期-->
                    <div class="part-inputpart-row">
                        <span
                            :class=" contractInfo.giveHouseTime ? 'content-have' : 'content-none' "></span>
                        <span class="part-inputpart-row-header">交房日期</span>
                        <span class="content-divide">|</span>
                        <div class="part-inputpart-row-graytext"
                            :class="0==contractInfo.giveHouseTime ? '': 'part-inputpart-row-redtext' "
                            id="startPeriodPanel-1" @click="clickGiveHouseTimeSelect">
                            <span class="part-inputpart-row-startPeriod">{{contractInfo.giveHouseTime}}</span>
                            <img class="part-inputpart-row-right part-inputpart-row-right-timepick"
                                src="../../../assets/images/timepick.png" />
                        </div>
                    </div>
                    <!--日期组件-->
<!--                    <van-calendar v-model="isGiveHouseTimeSelect" @confirm="startGiveHouseConfirm" :min-date="minDate" :max-date="maxDate" :show-confirm="false"/>-->
                    <!--交房日期组件-->
                    <van-popup v-model="isGiveHouseTimeSelect" position="bottom" class="selectTime">
                        <div class="date-dropDownList" >
                            <van-datetime-picker type="date" v-model="currentDate" :min-date="minDate" :max-date="maxDate" title="选择年月日" item-height="35px" @confirm="startGiveHouseConfirm" @cancel="cancel"/>
                        </div>
                    </van-popup>
                    <!--装修期限-->
                    <div class="part-inputpart-row">
                        <span
                            :class="contractInfo.startDecorateTime && contractInfo.endDecorateTime ? 'content-have' : 'content-none' "></span>
                        <span class="part-inputpart-row-header">装修期</span>
                        <span class="content-divide">|</span>
                        <div class="part-inputpart-row-graytext"
                            :class="contractInfo.startDecorateTime ? 'part-inputpart-row-redtext': '' "
                            id="startDecorate" @click="clickStartDecorateTimeShow">
                            <span class="part-inputpart-row-startPeriod">{{contractInfo.startDecorateTime}}</span>
                            <img class="part-inputpart-row-right part-inputpart-row-right-timepick"
                                src="../../../assets/images/timepick.png" />
                        </div>
                        <span class="part-inputpart-row-header">至</span>
                        <div class="part-inputpart-row-graytext"
                            :class="contractInfo.endDecorateTime ? 'part-inputpart-row-redtext': '' "
                            id="endDecorate" @click="clickEndDecorateTimeShow">
                            <span class="part-inputpart-row-endPeriod">{{contractInfo.endDecorateTime}}</span>
                            <img class="part-inputpart-row-right part-inputpart-row-right-timepick"
                                src="../../../assets/images/timepick.png" />
                        </div>
                    </div>
<!--                    <div>-->
<!--                        <van-calendar v-model="isEndDecorateTimeSelect" :show-confirm="false" @confirm="endDecorateTimeConfirm" :min-date="minDate" :max-date="maxDate"/>-->
<!--                    </div>-->
<!--                    装修结束期限时间控件-->
                    <van-popup v-model="isEndDecorateTimeSelect" position="bottom" class="selectTime">
                        <div class="date-dropDownList" >
                            <van-datetime-picker type="date" v-model="currentDate" :min-date="minDate" :max-date="maxDate" title="选择年月日" item-height="35px" @confirm="endDecorateTimeConfirm" @cancel="cancel"/>
                        </div>
                    </van-popup>
                    <!--免租期类型 showOrHiddenOfFreeTimeType  -->
                    <div id="freeTimeTypeSelect" class="part-inputpart-row" @click="isFreeTimeTypeSelect = true">
                        <span :class=" contractInfo.freeTimeType ? 'content-have' : 'content-none' "></span>
                        <span class="part-inputpart-row-header">免租期类型</span>
                        <span class="content-divide">|</span>
                        <span class="part-inputpart-row-normaltext">{{contractInfo.freeTimeType.label}}</span>
                        <img class="part-inputpart-row-right"
                             :class=" isFreeTimeTypeSelect ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
                             src="../../../assets/images/triangle.png" />
                    </div>
                    <!--免租期下拉选-->
                    <div>
                        <van-popup v-model="isFreeTimeTypeSelect" position="bottom">
                            <van-picker show-toolbar value-key="label" :columns="freeTimeTypeArr"
                                @cancel="isFreeTimeTypeSelect = false" @confirm="selectFreeTimeType" />
                        </van-popup>
                    </div>
                    <!--付款方式  clickPaymentMethodShow-->
                    <div id="paymentMethodPanel" class="part-inputpart-row" @click="isPaymentMethodShow = true">
                        <span :class=" contractInfo.paymentMethod ? 'content-have' : 'content-none' "></span>
                        <span class="part-inputpart-row-header">付款方式</span>
                        <span class="content-divide">|</span>
                        <span class="part-inputpart-row-graytext"
                            :class="contractInfo.paymentMethod ? 'part-inputpart-row-normaltext':'' ">{{contractInfo.paymentMethod.label}}</span>
                        <img class="part-inputpart-row-right"
                            :class=" isPaymentMethodShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
                            src="../../../assets/images/triangle.png" />
                    </div>
                    <div>
                        <van-popup v-model="isPaymentMethodShow" position="bottom">
                            <van-picker show-toolbar value-key="label" :columns="paymentMethodArr"
                                @cancel="isPaymentMethodShow = false" @confirm="selectPaymentMethodOption" />
                        </van-popup>
                    </div>

                    <div class="part-inputpart-row">
                        <span
                                :class=" 0==contractInfo.leaseTerm ? 'content-none' : 'content-have' "></span>
                        <span class="part-inputpart-row-header">合同年限</span>
                        <span class="content-divide">|</span>
                        <div class="nopadding">
                            <van-field class="nopadding" :formatter="formatter" type="digit" v-model="contractInfo.leaseTerm" placeholder="合同年限"
                                       v-bind:readonly="!(contractInfo.giveHouseTime&&contractInfo.endDecorateTime)" />
                        </div>
                        <span class="part-inputpart-row-right part-inputpart-row-short-graytext">年</span>
                    </div>

                    <div class="part-inputpart-row" v-show="false">
                        <span class="part-inputpart-row-enablenon part-inputpart-row-header">维修基金状态</span>
                        <span class="content-divide">|</span>
                        <span :class=" isOnlineSign ? 'content-have' : 'content-none'"></span>
                        <span class="part-inputpart-row-normaltext" >关闭</span>
                        <span :class=" isUplineSign ? 'content-have' : 'content-none'"></span>
                        <span class="part-inputpart-row-normaltext" >开启</span>
                    </div>

                    <div class="part-inputpart-row" v-if="contractInfo.Latestleaseate=='1'&&flagType!='1'">
                        <span class="part-inputpart-row-enablenon part-inputpart-row-header">有维修基金</span>
                        <span class="content-divide">|</span>
                        <span :class=" contractInfo.haveLatestleaseate=='0' ? 'content-have' : 'content-none'"></span>
                        <span class="part-inputpart-row-normaltext" @click="chooseOffMaintain">否</span>
                        <span :class=" contractInfo.haveLatestleaseate=='1' ? 'content-have' : 'content-none'"></span>
                        <span class="part-inputpart-row-normaltext" @click="chooseOnMaintain">是</span>
                    </div>

                    <div class="part-inputpart-row" v-if="contractInfo.Latestleaseate=='1'&&contractInfo.haveLatestleaseate=='1'&&contractInfo.ownerMaintenanceFund>0">
                        <span :class=" contractInfo.ownerMaintenanceFund ? 'content-have' : 'content-none' "></span>
                        <span class="part-inputpart-row-header">维修基金金额</span>
                        <span class="content-divide">|</span>
                        <div class="nopadding">
                            <van-field class="nopadding" :formatter="formatter" type="number" disabled v-model="contractInfo.ownerMaintenanceFund" />
                        </div>
                    </div>

                    <!--免租期列表-->
                    <div v-if="contractInfo.leaseTerm>0 && contractInfo.endDecorateTime">
                        <span class="part-subtitle">免租期设定</span>
                        <div v-for="(item,index) in freeRentArr" :key="index">
                            <div class="otherExpenses-inputpart">
                                <div class="otherExpenses-inputpart-row">
                                    <div
                                            :class=" contractInfo.supportingService? 'content-none' : 'content-have'">
                                    </div>
                                    <span class="part-inputpart-row-header">第{{index+1}}年</span>
                                    <span class="content-divide">|</span>
                                    <div class="nopadding">
                                        <van-field  class="nopadding mianzuqi" type="digit" :formatter="formatter" placeholder="请输入免租几个月" @input="changeFree(index,item.noFeeMonthLong,'month')"
                                                    v-model="item.noFeeMonthLong" />
                                                    <span class="nopay-month">月</span>
                                        <van-field  class="nopadding mianzuqi" type="digit" :formatter="formatter" placeholder="请输入免租天数" @input="changeFree(index,item.noFeeTimeLong,'day')"
                                                    v-model="item.noFeeTimeLong" />
                                    </div>
                                    <span class="part-inputpart-row-right">天</span>
                                </div>
                                <div class="otherExpenses-inputpart-column">
                                    <div>
                                        <span
                                                class="part-inputpart-column-enablenon part-inputpart-row-header">免租期限：<span class="part-inputpart-row-redtext">{{item.timeFree}}</span>
                                        </span>
                                    </div>
                                    <div>
                                        <span
                                                class="part-inputpart-column-enablenon part-inputpart-row-header">每年委托期限：<span class="spanStyle">{{item.timeTotal}}</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="part-inputpart">
                            <div class="part-inputpart-row">
                                <span class="part-inputpart-column-enablenon part-inputpart-row-header">总免租期</span>
                                <span class="content-divide">|</span>
                                <span class="part-inputpart-row-greentext">{{freeMonthTotal}}</span>
                                <span class="part-inputpart-row-right">月</span>
                                <span class="part-inputpart-row-greentext">{{freeTimeTotal}}</span>
                                <span class="part-inputpart-row-right">天</span>
                            </div>
                        </div>
                    </div>

                    <!--合同期限-->
                    <div class="part-inputpart-row">
                        <span
                            :class=" contractInfo.noFeeBeginTime || contractInfo.noFeeEndTime ? 'content-have' : 'content-none' "></span>
                        <span class="part-inputpart-row-header">合同期</span>
                        <span class="content-divide">|</span>
                        <div class="part-inputpart-row-graytext"
                            :class="0==contractInfo.noFeeBeginTime ? '': 'part-inputpart-row-redtext' "
                            id="startPeriodPanel" @click="clickStartPeriodShow">
                            <span class="part-inputpart-row-startPeriod">{{contractInfo.noFeeBeginTime}}</span>
                            <img class="part-inputpart-row-right part-inputpart-row-right-timepick"
                                src="../../../assets/images/timepick.png" />
                        </div>
                        <span class="part-inputpart-row-header">至</span>
                        <div class="part-inputpart-row-graytext"
                            :class="0==contractInfo.noFeeEndTime ? '': 'part-inputpart-row-redtext' "
                            id="endPeriodPanel" @click="clickEndPeriodShow">
                            <span class="part-inputpart-row-endPeriod">{{contractInfo.noFeeEndTime}}</span>
                            <img class="part-inputpart-row-right part-inputpart-row-right-timepick"
                                src="../../../assets/images/timepick.png" />
                        </div>
                    </div>

                    <!--租金和押金-->
                    <div>
                        <div class="part-inputpart-row">
                            <div :class=" contractInfo.rent? 'content-have' : 'content-none'"></div>
                            <span class="part-inputpart-row-header">租金</span>
                            <span class="content-divide">|</span>
                            <div class="nopadding">
                                <van-field class="nopadding" :formatter="formatter" type="digit" v-model="contractInfo.rent" placeholder="租金"  />
                            </div>
                            <span class="part-inputpart-row-right part-inputpart-row-short-graytext">元</span>
                        </div>
                        <div class="part-inputpart-row">
                            <span class="part-inputpart-row-enablenon part-inputpart-row-header">押金</span>
                            <span class="content-divide">|</span>
                            <div class="nopadding">
                                <van-field class="nopadding" :formatter="formatter" type="digit" v-model="contractInfo.deposit" placeholder="押金" />
                            </div>
                            <span class="part-inputpart-row-right part-inputpart-row-short-graytext">元</span>
                        </div>
                    </div>

                    <!--权属类型 showOrHiddenOfOwnershipType-->
                    <div id="ownershipTypeSelect" class="part-inputpart-row" @click="isOwnershipTypeSelect = true">
                        <span :class=" contractInfo.ownershipType.label ? 'content-have' : 'content-none' "></span>
                        <span class="part-inputpart-row-header">权属类型</span>
                        <span class="content-divide">|</span>
                        <span class="part-inputpart-row-graytext"
                            :class="contractInfo.ownershipType.label ? 'part-inputpart-row-normaltext':'' ">{{contractInfo.ownershipType.label||contractInfo.ownershipType}}</span>
                        <img class="part-inputpart-row-right"
                            :class=" isOwnershipTypeSelect ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
                            src="../../../assets/images/triangle.png" />
                    </div>
                    <!--权属类型下拉选-->
                    <div>
                        <van-popup v-model="isOwnershipTypeSelect" position="bottom">
                            <van-picker show-toolbar value-key="label" :columns="ownershipTypeArr"
                                @cancel="isOwnershipTypeSelect = false" @confirm="selectOwnershipType" />
                        </van-popup>
                    </div>
                    <!--产权证号-->
                    <div class="part-inputpart-row">
                        <span :class=" ownerInfo.rightNumber ? 'content-have' : 'content-none' "></span>
                        <span class="part-inputpart-row-header">产权证号</span>
                        <span class="content-divide">|</span>
                        <div class="nopadding">
                            <van-field class="nopadding" :formatter="formatter" type="number" v-model="ownerInfo.rightNumber" />
                        </div>
                    </div>
                    <!--面积-->
                    <div class="part-inputpart-row">
                        <span :class=" ownerInfo.buildArea? 'content-have' : 'content-none' "></span>
                        <span class="part-inputpart-row-header">面积</span>
                        <span class="content-divide">|</span>
                        <div class="nopadding">
                            <van-field class="nopadding" :formatter="formatter" type="number" v-model="ownerInfo.buildArea" />
                        </div>
                        <span class="part-inputpart-row-right part-inputpart-row-short-graytext">㎡</span>
                    </div>
                    <!--朝向类型 showOrHiddenOfToWhereType-->
                    <div id="toWhereTypeSelect" class="part-inputpart-row" @click="isToWhereTypeSelect = true">
                        <span :class=" contractInfo.toWhereType ? 'content-have' : 'content-none' "></span>
                        <span class="part-inputpart-row-header">朝向</span>
                        <span class="content-divide">|</span>
                        <span class="part-inputpart-row-graytext"
                            :class="contractInfo.toWhereType ? 'part-inputpart-row-normaltext':'' ">{{contractInfo.toWhereType.label}}</span>
                        <img class="part-inputpart-row-right"
                            :class=" isToWhereTypeSelect ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
                            src="../../../assets/images/triangle.png" />
                    </div>
                    <!--朝向类型下拉选-->
                    <div>
                        <van-popup v-model="isToWhereTypeSelect" position="bottom">
                            <van-picker show-toolbar value-key="label" :columns="toWhereTypeArr"
                                @cancel="isToWhereTypeSelect = false" @confirm="selectToWhereType" />
                        </van-popup>
                    </div>
                    <!--供暖类型 showOrHiddenOfHeatingType-->
                    <div id="heatingTypeSelect" class="part-inputpart-row" @click="isHeatingTypeSelect = true">
                        <span class="part-inputpart-row-enablenon part-inputpart-row-header">供暖情况</span>
                        <span class="content-divide">|</span>
                        <span class="part-inputpart-row-graytext"
                            :class="contractInfo.heatingType ? 'part-inputpart-row-normaltext':'' ">{{contractInfo.heatingType.label}}</span>
                        <img class="part-inputpart-row-right"
                            :class=" isHeatingTypeSelect ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
                            src="../../../assets/images/triangle.png" />
                    </div>
                    <!--供暖类型下拉选-->
                    <div>
                        <van-popup v-model="isHeatingTypeSelect" position="bottom">
                            <van-picker show-toolbar value-key="label" :columns="heatingTypeArr"
                                @cancel="isHeatingTypeSelect = false" @confirm="selectHeatingType" />
                        </van-popup>
                    </div>
                    <!--抵押类型 showOrHiddenOfMortgageType-->
                    <div id="mortgageTypeSelect" class="part-inputpart-row" @click="isMortgageTypeSelect = true">
                        <span class="part-inputpart-row-enablenon part-inputpart-row-header">抵押情况</span>
                        <span class="content-divide">|</span>
                        <span class="part-inputpart-row-graytext"
                            :class="contractInfo.mortgageType ? 'part-inputpart-row-normaltext':'' ">{{contractInfo.mortgageType.label}}</span>
                        <img class="part-inputpart-row-right"
                            :class=" isMortgageTypeSelect ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
                            src="../../../assets/images/triangle.png" />
                    </div>
                    <!--抵押类型下拉选-->
                    <div>
                        <van-popup v-model="isMortgageTypeSelect" position="bottom">
                            <van-picker show-toolbar value-key="label" :columns="mortgageTypeArr"
                                @cancel="isMortgageTypeSelect = false" @confirm="selectMortgageType" />
                        </van-popup>
                    </div>
                </div>
            </div>

            <div class="part">
                <span class="part-subtitle">产权证明</span>
                <div class="part-inputpart">
                    <div class="part-inputpart-textarea part-uploadpic-content">
                        <!-- <div class="part-uploadpic-module" v-for="(item,index) in contractInfo.enclosurePropertyPicList"
                            :key="index">
                            <img class="part-uploadpic-module-pic" :src="item.path" />
                            <div class="part-uploadpic-text">附件{{index+1}}</div>
                        </div> -->
                        <!--                        上传图片按钮-->
                        <van-uploader class="part-uploadpic-van-uploader" upload-icon="plus" v-model="contractInfo.enclosurePropertyPicList" multiple
                                      image-fit="contain"
                            :max-count="10" :after-read="afterReadTest" >
                        </van-uploader>
                    </div>
                </div>
                <!--线下合同-->
                <span class="part-subtitle">线下合同</span>
                <div class="part-inputpart">
                    <div class="part-inputpart-textarea part-uploadpic-content">
                        <!--                        上传图片按钮-->
                        <van-uploader class="part-uploadpic-van-uploader"  upload-icon="plus" v-model="contractInfo.outLinecontratPicList" multiple
                                      image-fit="contain"
                                      :max-count="10" :after-read="afterReadTest">
                        </van-uploader>
                    </div>
                </div>
                <span class="part-subtitle">补充说明</span>
                <div class="part-inputpart">
                    <div class="part-inputpart-textarea">
                        <textarea v-model="contractInfo.explanationDescribe" placeholder="填写补充说明"></textarea>
                    </div>
                </div>
                <!--标签-->
                <div class="part-inputpart-row">
                    <span class="text-heating">标签</span>
                    <span class="content-divide">|</span>
                    <div class="divid-heating" @click="choosePaperContent(item)" v-for="(item,ind) in contractLabel" :key="ind">
<!--                        <div class="text-icon" ></div>-->
                        <div :class="item.isChoose?'text-contractActive':'text-contract'">{{item.dictionaryTitle}}</div>
                    </div>
                </div>
            </div>
            <div class="part">
                <span class="part-subtitle">房屋签约人</span>
                <div class="part-inputpart">
                    <div id="signatoryPanel" class="part-inputpart-row" @click="clickSignatoryShow">
                        <span :class=" contractInfo.signatory ? 'content-have' : 'content-none' "></span>
                        <span class="part-inputpart-row-header">签约人</span>
                        <span class="content-divide">|</span>
                        <span class="part-inputpart-row-graytext"
                            :class="contractInfo.signatory ? 'part-inputpart-row-normaltext':'' ">{{contractInfo.signatory.title}}</span>
                        <img class="part-inputpart-row-right"
                            :class=" isSignatoryShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
                            src="../../../assets/images/youjiantou.png" />
                    </div>
                    <div>
                        <div class="part-inputpart part-inputpart-dropDownList" style="height:40%"
                            v-if="isSignatoryShow">
                            <treeSelect class="part-inputpart-dropDownList-option" :itemlist="signatoryArr"
                                placeholder="请选择" nodatatext="没有找到" :show="isSignatoryShow"
                                @item-click="selectSignatoryOption"></treeSelect>
                        </div>
                    </div>
                    <!--签约人部门-->
                    <div class="part-inputpart-row">
                        <span class="part-inputpart-row-enablenon part-inputpart-row-header">签约人部门</span>
                        <span class="content-divide">|</span>
                        <span class="part-inputpart-row-normaltext">{{contractInfo.signDepartment.label}}</span>
                    </div>
                    <!--合作人部门-->
                    <div id="partnerPanel" class="part-inputpart-row" @click="clickPartnerShow">
                        <span class="part-inputpart-row-enablenon part-inputpart-row-header">合作人</span>
                        <span class="content-divide">|</span>
                        <span class="part-inputpart-row-graytext"
                            :class="contractInfo.partner ? 'part-inputpart-row-normaltext':'' ">{{contractInfo.partner.staffName}}</span>
                        <img class="part-inputpart-row-right"
                            :class=" isPartnerShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
                            src="../../../assets/images/youjiantou.png" />
                    </div>
                    <!--合作人下拉列表-->
                    <div>
                        <div class="part-inputpart part-inputpart-dropDownList" style="height:40%" v-if="isPartnerShow">
                            <treeSelect class="part-inputpart-dropDownList-option" :itemlist="partnerArr"
                                placeholder="请选择" nodatatext="没有找到" :show="isPartnerShow"
                                @item-click="selectPartnerOption"></treeSelect>
                        </div>
                    </div>
<!--                  contractInfo.partnerDepartment.label  -->
                    <div class="part-inputpart-row">
                        <span class="part-inputpart-row-enablenon part-inputpart-row-header">合作人部门</span>
                        <span class="content-divide">|</span>
                        <span class="part-inputpart-row-normaltext">{{contractInfo.partner.departmentName}}</span>
                    </div>
                    <!--合作占比-->
                    <div id="cooperationTypeSelect" class="part-inputpart-row" @click="showOrHiddenOfCooperationType"
                        v-if="isPartnerSelected || contractInfo.partner">
                        <span :class=" contractInfo.cooperationType ? 'content-have' : 'content-none' "></span>
                        <span class="part-inputpart-row-header">合作占比</span>
                        <span class="content-divide">|</span>
                        <span class="part-inputpart-row-graytext"
                            :class="contractInfo.cooperationType ? 'part-inputpart-row-normaltext':'' ">{{contractInfo.cooperationType.label || '请选择'}}</span>
                        <img class="part-inputpart-row-right"
                            :class=" isCooperationTypeSelect ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
                            src="../../../assets/images/triangle.png" />
                    </div>
                    <!--合作占比下拉选-->
                    <div>
                        <van-popup v-model="isCooperationTypeSelect" position="bottom">
                            <van-picker show-toolbar value-key="label" :columns="cooperationTypeArr"
                                @cancel="isCooperationTypeSelect = false" @confirm="selectCooperationType" />
                        </van-popup>
                    </div>

                    <!--推荐人-->
                    <div id="partnershipProportionPanel" class="part-inputpart-row"
                        @click="clickPartnershipProportionShow">
                        <span class="part-inputpart-row-enablenon part-inputpart-row-header">渠道人</span>
                        <span class="content-divide">|</span>
                        <span class="part-inputpart-row-graytext"
                            :class="contractInfo.partnershipProportion ? 'part-inputpart-row-normaltext':'' ">{{contractInfo.partnershipProportion.label}}</span>
                        <img class="part-inputpart-row-right"
                            :class=" isPartnershipProportionShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
                            src="../../../assets/images/triangle.png" />
                    </div>
                    <div>
                        <van-popup v-model="isPartnershipProportionShow" position="bottom">
                            <div class="searchDiv">
                                <van-search  placeholder="请输入" v-model="referrerText" size="large" @input="initReferrer()"/>
                            </div>
                            <van-picker show-toolbar value-key="label" :columns="partnershipProportionArr"
                                @cancel="isPartnershipProportionShow = false"
                                @confirm="selectPartnershipProportionOption" />
                        </van-popup>
                    </div>
                    <div class="part-inputpart-row" v-if="contractInfo.partnershipProportion">
                        <div :class=" contractInfo.channelMoney? 'content-have' : 'content-none'"></div>
                        <span class="part-inputpart-row-header">渠道费</span>
                        <span class="content-divide">|</span>
                        <div class="nopadding">
                            <van-field class="nopadding" :formatter="formatter" type="digit" v-model="contractInfo.channelMoney" placeholder="渠道费"  @on-change="channelMoneyInput"/>
                        </div>
                        <span class="part-inputpart-row-right part-inputpart-row-short-graytext">元</span>
                    </div>
                    <!-- 是否装修 flagType:0 潜房签约,1 业主合同修改,2 业主合同续租 -->
                <div class="part-inputpart-row" style="font-size: 0.4rem">
                    <span :class=" contractInfo.isRenovation ? 'content-have' : 'content-none' "></span>
                    <div class="part-inputpart-row-header" style="margin-left: 0.6rem">是否装修</div>
                    <span class="content-divide">|</span>
                    <van-radio-group class="radio_group" :disabled="flagType=='0'" direction="horizontal" v-model="contractInfo.isRenovation" style="margin-left: 2.4rem" >
                        <van-radio name="0">
                            <template #icon="props">
                                <span :class="props.checked ? 'isChecked radio_span':'radio_span'">是</span>
                            </template>
                        </van-radio>
                        <span class="content-divide  part-inputpart-row-short-graytext">|</span>
                        <van-radio name="1">
                            <template #icon="props">
                                <span :class="props.checked ? 'isChecked radio_span':'radio_span'">否</span>
                            </template>
                        </van-radio>
                    </van-radio-group>
                </div>
                </div>
            </div>
        </div>
        <div class="part-horizontalLine"></div>
        <div class="part part-button" @click="save" :class="(checkCanSave()? 'part-button-enabled' : '' )">{{saveFlag?'保存':'保存中'}}</div>
    </div>
</template>

<script>
    import {
        NavBar,
        DatetimePicker,
        Uploader,
        Calendar,
        Tag,
        Toast,
        Picker,
        Popup,
        RadioGroup,
        Radio,
        Field,
        search
    } from "vant";
    import treeSelect from "../../../components/treeSelect/treeSelect";
    import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
    import util from "../util";
    import config from "../config";
    import {
        saveOrUpdateOwnercontract,
        queryStaffList,
        createContractCode,
        findDepartmentByStaffId,
        seeWithUserList,
        base64Upload,
        findUserInfoByMobile,
        queryBaseData,
        ownercontractfindOwnerContractInfo,
        ownercontractCycle,
        queryCityAndProvince,
        queryOwnerMaintenanceFund,
        remoteMobileSearch,
        policyDecisionData,
    } from "../../../getData/getData";
    import {
        responseUtil,
        getStaffId, dealImageSingle,dealImage
    } from "../../../libs/rongxunUtil";
    import eventBus from "../../../components/rongxun/rx-navBar/envbus";
    export default {
        name: "NewContract",
        components: {
            [NavBar.name]: NavBar,
            [DatetimePicker.name]: DatetimePicker,
            [Uploader.name]: Uploader,
            [Tag.name]: Tag,
            [Toast.name]: Toast,
            [Picker.name]: Picker,
            [Popup.name]: Popup,
            [RadioGroup.name]: RadioGroup,
            [Radio.name]: Radio,
            [Field.name]:Field,
            [Calendar.name]:Calendar,
            [search .name]:search,
            treeSelect,
            rxNavBar,
        },
        data() {
            return {
                warnSubBank:'未找到支行',
                notFindSubBank:false,  //点击了未找到支行
                referrerText:'',  //推荐人检索
                loadingFlag:false,//上传图片loading
                saveFlag:true,//true 可以保存 false 不可以保存
                terminateInfoParam:[],//租约变更信息
                flagType:'',
                // 免租期列表
                // freePayList: [],
                // 页面修改 start
                //控制签约人角色的部分
                isOwnerTypeSelect: false,
                isOwnerTypeSelectAready: false,
                ownerTypeArr: [{
                        label: "业主",
                        value: 0
                    },
                    {
                        label: "代理人",
                        value: 1
                    }
                ],
                //线上签约或者线下签约
                isOnlineSign: true, //默认线上签约
                isUplineSign: false,
                isChooseProxy: false, //点击代理人
                isChooseCoOwner: false, //共有权人信息
                isOwnerFlag:true,   //业主签约还是代理人签约
                proxyRadio:'0',
                coOwnerRadio:'0',
                //选择银行
                isBankTypeSelectAready: false,
                isBankTypeSelect: false,
                bankTypeArr: [],
                savingsCertificateList:[],//储蓄类型 (银行卡,存折)
                bankTypeChoose:false,//储蓄类型选择
                bankTypeInfo:{//储蓄类型信息

                },
                isGiveHouseTimeSelect: false, //是否选择交房时间
                minDate: new Date(2018, 0, 1),
                currentDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
                maxDate: new Date(2070, 0, 31),
                isStartDecorateTimeSelect: "", //选择装修开始时间
                isEndDecorateTimeSelect: false, //装修结束时间
                isFreeTimeTypeSelectAready: false,
                isFreeTimeTypeSelect: false,
                freeTimeTypeArr: [],
                //免租期类型
                isOwnershipTypeSelectAready: false,
                isOwnershipTypeSelect: false,
                ownershipTypeArr: [], //权属类型
                isToWhereTypeSelectAready: false,
                isToWhereTypeSelect: false,
                toWhereTypeArr: [], //朝向类型
                isHeatingTypeSelectAready: false,
                isHeatingTypeSelect: false,
                heatingTypeArr: [], //供暖类型
                isMortgageTypeSelectAready: false,
                isMortgageTypeSelect: false,
                mortgageTypeArr: [], //抵押类型
                freeRentArr: [], //临时的数组
                freeMonthTotal:0,
                freeTimeTotal: 0, //存储总免租期
                isCooperationTypeSelectAready: false,
                isCooperationTypeSelect: false,
                cooperationTypeArr: [], //合作比例
                // 页面修改 end
                isFixed: false,
                //显示 哪个信息
                whichInfo: "0",
                //证件
                isCardShow: false,
                isCoOwnerCardShow: false,
                isProxyCardShow: false,
                isCardSelected: false,
                isCoOwnerCardSelected: false,
                isProxyCardSelected: false,
                isCohabitingPersonCardShow: false,
                isCohabitingPersonCardSelected: false,
                //付款方式
                isPaymentMethodShow: false,
                isPaymentMethodSelected: false,
                //起始日期
                isStartPeriodShow: false,
                //截止日期
                isEndPeriodShow: false,
                //入住日期
                isCheckInDateShow: false,

                currentDate: new Date(),
                //签约人
                isSignatoryShow: false,
                isSignatorySelected: false,
                //合伙人
                isPartnerShow: false,
                isPartnerSelected: false,
                //合伙占比
                isPartnershipProportionShow: false,
                isPartnershipProportionSelected: false,
                //房源地址
                houseAddress: "",
                propertyRightAddress:'',
                contracthistoryId:"",//合同历史id
                title:'',
                ownerInfo: {
                    ownerNameAuthentication:'0',//用户实名认证状态
                    phone: "",
                    name: "",
                    sex: "",
                    certificateType: {},
                    certificateCode: "",
                    email: "", //邮箱
                    receiver: "", //收款人
                    emergencyAddress: "",
                    emergencyContactName: "",
                    emergencyContactPhone: "",
                    // 证件正面
                    yzFrontOfCard: require('../../../assets/images/default_img_4.png'),
                    // 证件背面
                    yzReverseOfCard: require('../../../assets/images/default_img_5.png'),
                    // 手持证件图片
                    yzHoldCard: require('../../../assets/images/default_img_3.png'),
                    // 银行卡正面
                    bankCardFront: require('../../../assets/images/default_img_2.png'),
                    // 银行卡背面
                    bankCardReverse: require('../../../assets/images/default_img_1.png'),
                    //是否有同住
                    liveTogether: "",
                    bankChanel: "", //所属支行
                    bankBob: "", //所属营业部
                    bankCard: "", //银行卡号
                    rightNumber: "", //产权编号
                    buildArea: "", //面积
                    estateRoom_id: '', //房间id
                    potentialResource_id: '' //潜房id
                },
                proxyInfo: {
                    //代理人信息
                    name: "",
                    phone: "",
                    certificateType: {},
                    agentNameAuthentication:0,
                    certificateCode: "", //身份证号码
                    wtrFrontOfCard: require('../../../assets/images/default_img_4.png'), //身份证前面
                    wtrReverseOfCard: require('../../../assets/images/default_img_5.png'), //身份证后面
                    entrustment: require('../../../assets/images/default_img_6.png'), //业主委托
                    holdEntrustment: require('../../../assets/images/default_img_6.png'), //业主手持委托
                    cachePhoto:'',//缓存业主手持身份证,来回切换时用到
                },
                coOwner: {
                    //共有权人信息
                    name: "",
                    phone: "",
                    IdCard: "",
                    certificateType: {},
                    commonOwnerFrontOfCard: require('../../../assets/images/default_img_4.png'), //身份证前面
                    commonOwnerReverseOfCard: require('../../../assets/images/default_img_5.png') //身份证后面
                },
                cohabitingPersonInfo: {
                    phone: "",
                    name: "",
                    sex: "",
                    certificateType: "",
                    certificateCode: "",
                    // 证件正面图片地址
                    yzFrontOfCard: '',
                    // 证件被面图片地址
                    yzReverseOfCard: '',
                    // 手持证件图片
                    yzHoldCard: ''
                },
                contractInfo: {
                    //合同签约人
                    signedType: "",
                    bankType: "请选择", //银行类型
                    giveHouseTime: "", //交房日期
                    startDecorateTime: "", //装修开始时间
                    endDecorateTime: "", //装修结束时间
                    freeTimeType: "", //免租期类型
                    ownershipType: "请选择", //权属类型
                    toWhereType: {
                        id:'',
                        label:''
                    }, //朝向
                    heatingType: "供暖情况", //供暖情况
                    mortgageType: "抵押情况", //抵押类型
                    cooperationType:'', //合作占比
                    //合同编号
                    number: "",
                    // 续租时使用
                    newNumber:"",
                    //合同类型
                    type: "新签",
                    // cutOffDate: '2020-12-21',
                    //付款方式
                    paymentMethod: "",
                    //租金
                    rent: "",
                    //押金
                    deposit: "",
                    // 租期
                    leaseTerm: "",
                    // 开始期限
                    noFeeBeginTime: "",
                    // 结束期限
                    noFeeEndTime: "",
                    // 入住日期
                    checkInDate: "",
                    //标签列表
                    labelList: [],
                    //是否有配套服务
                    supportingService: "",
                    //其他费用
                    otherExpensesList: [],
                    //优惠活动
                    discounts: "",
                    //补充说明
                    explanationDescribe: "",
                    //附件
                    enclosurePropertyPicList: [],
                    //线下合同
                    outLinecontratPicList: [],
                    outLineImgChangeType:false,
                    enclosureImgChangeType:false,
                    //签约人
                    signatory: "",
                    //合伙人
                    partner: "",
                    // 签约人部门
                    signDepartment: {
                        label: ""
                    },
                    // 合作人部门
                    partnerDepartment: {
                        label: ""
                    },
                    //推荐人id
                    partnershipProportion: "",
                    channelMoney:"",         //渠道费
                    province:{id:'',name:''},
                    city:{id:'',name:''},
                    haveLatestleaseate:'',
                    isRenovation: "0",
                },
                certificateTypeArr: [""],
                paymentMethodArr: [],
                signatoryArr: [],
                partnerArr: [],
                //推荐人
                partnershipProportionArr: [],
                // 合同标签
                contractLabel: [],
                firstEdit:false,    //点击修改进入的页面
                secondeEdit:false,
                thirdEdit:false,
                costTimeLimit:[],
                provinceArr:[],
                cityArr:[],
                isProvinceSelect: false,
                isCitySelect: false,
                newOwnerMaintenanceFund:'',
                interbankId:'',
                houseRoomCount:'',
                houseRoomCountStandard:'',
            };
        },

        activated() {
            eventBus.$on('referrer', function(data){
                console.log(data)
                if(data.signPersonInfo){   //签约人
                    this.contractInfo.signatory = data.signPersonInfo
                }
                if(data.cooperationPersonInfo){   //合作人
                    this.contractInfo.partner = data.cooperationPersonInfo
                }
                if(data.interbankId){
                this.interbankId=data.interbankId
                this.ownerInfo.bankBob=data.interBankNo
                this.ownerInfo.bankChanel=data.subBankName
                }
            }.bind(this));
        },

        created() {

            this.getProvinceAndCity()
            //this.getQueryOwnerMaintenanceFund()
        },

        mounted() {
            //事件监听滚动条
            window.addEventListener("scroll", this.watchScroll,true);
            this.initData();
            this.initHouseRoomCount()
            this.ownerInfo.buildArea = this.$route.params.builtUpArea
            this.contractInfo.toWhereType.id = this.$route.params.orientation_id
            this.contractInfo.toWhereType.value = this.$route.params.orientation_id
            this.contractInfo.toWhereType.label = this.$route.params.applyorientationName
        },

        destroyed() {
            window.removeEventListener("scroll", this.watchScroll, true);
        },

        //监听数值变化
        watch: {
            "contractInfo.rent": function (newVal, oldVal) {
                var reg = /^(\d{0,8})(\.(\d{0,2}))?$/g;
                if (!reg.test(newVal)) {
                    if (newVal == "") {
                        this.contractInfo.rent = "";
                        return;
                    }
                    this.contractInfo.rent = oldVal;
                } else {
                    this.contractInfo.rent = newVal;
                }
            },
            "contractInfo.deposit": function (newVal, oldVal) {
                var reg = /^(\d{0,8})(\.(\d{0,2}))?$/g;
                if (!reg.test(newVal)) {
                    if (newVal == "") {
                        this.contractInfo.deposit = "";
                        return;
                    }
                    this.contractInfo.deposit = oldVal;
                } else {
                    this.contractInfo.deposit = newVal;
                }
            },
            "contractInfo.signatory.id": function (newVal) {
                if (newVal && newVal.value != -1 && newVal != "") {
                    let that = this;
                    let initData = {
                        staffId: newVal
                    };
                    findDepartmentByStaffId(initData).then(function (response) {
                        responseUtil.dealResponse(that, response, () => {
                            if (response.data.data != null) {
                                that.contractInfo.signDepartment.label = ''
                                //9.2修改如果部门大于三级从第三级开始显示
                                let departmentList = response.data.data
                                if(departmentList.length > 2){
                                    for(let i = 2; i < departmentList.length; i++){
                                        that.contractInfo.signDepartment.label += departmentList[i].departmentName + " ";
                                        that.contractInfo.signDepartment.id = departmentList[i].id;
                                    }

                                }else{
                                    response.data.data.forEach(item => {
                                        that.contractInfo.signDepartment.label +=
                                            item.departmentName + " ";
                                        that.contractInfo.signDepartment.id = item.id;
                                    });
                                }

                            }
                        });
                    });
                }
            },
            "contractInfo.partner": function (newVal) {
              console.log('监听')
                    let that = this;
                if (newVal.id && newVal.value != -1 && newVal.id != '') {
                        if(newVal.id == that.contractInfo.signatory.id){
                            responseUtil.alertMsg(that,'合作人和签约人不能是同一个人')
                            that.contractInfo.partner = "";
                            return
                        }
                    let initData = {
                        staffId: newVal.id
                    };
                    findDepartmentByStaffId(initData).then(function (response) {
                        responseUtil.dealResponse(that, response, () => {
                            if (response.data.data != null) {
                                response.data.data.forEach(item => {
                                    that.contractInfo.partnerDepartment.label +=
                                        item.departmentName + " ";
                                    that.contractInfo.partnerDepartment.id = item.id;
                                });
                            }
                        });
                    });
                } else {
                    this.contractInfo.partnerDepartment.label = "";
                    this.isPartnerSelected = false;
                }
            },
            'contractInfo.endDecorateTime':function (val) {
                if(this.secondEdit){
                    this.secondEdit=false
                    return
                }
                if(this.freeRentArr.length==0){
                   return
                }
                if (val) {
                    let t1, t2, t3, t4, total1, total2;
                    for (let i = 0; i < this.freeRentArr.length; i++) {
                        if (i == 0) {
                            if (
                                this.contractInfo.freeTimeType.value == config.FREE_PAY_INNTER
                            ) {
                                //免租期限

                                t1 = this.contractInfo.noFeeBeginTime;
                                //t2 = this.getNewDataDays(t1, util.getMonthLastDay(t1)-1);
                                t2=util.dateFormat(util.dateAddDay(util.dateAddMonth(new Date(t1),1),-1))
                                total1 = t1 + "至" + t2;
                                //合同年限
                                t3 = t1;
                                t4 = this.getNewDataYears1(t3, 1);
                                total2 = t3 + "至" + t4;
                            }
                        } else {
                            if (
                                this.contractInfo.freeTimeType.value == config.FREE_PAY_INNTER
                            ) {
                                //免租期限
                                let t = this.contractInfo.noFeeBeginTime;
                                t1 = this.getNewDataYears2(t, i);
                                //t2 = this.getNewDataDays(t1, util.getMonthLastDay(t1)-1);
                                t2=util.dateFormat(util.dateAddDay(util.dateAddMonth(new Date(t1),1),-1))
                                total1 = t1 + "至" + t2;
                                //合同年限
                                t3 = t1;
                                t4 = this.getNewDataYears1(t3, 1);
                                total2 = t3 + "至" + t4;
                            }
                        }
                       this.freeRentArr[i] = {
                            num: i + 1,
                            noFeeMonthLong:1,
                            noFeeTimeLong: 0,//util.getMonthLastDay(t1),
                            timeFree: total1,
                            timeTotal: total2,
                            noFeeBeginTime: t1,
                            noFeeEndTime: t2,
                            everyYearEntrustBeginTime: t3,
                            everyYearEntrustEndTime: t4
                        };
                    }
                }
            },
            'contractInfo.freeTimeType.value':function (val) {
                if(this.thirdEdit){
                    this.thirdEdit=false
                    return
                }
                if(this.freeRentArr.length==0){
                   return
                }
                if (val) {
                    let t1, t2, t3, t4, total1, total2;
                    for (let i = 0; i < this.freeRentArr.length; i++) {
                        if (i == 0) {
                            if (
                                this.contractInfo.freeTimeType.value == config.FREE_PAY_INNTER
                            ) {
                                //免租期限

                                t1 = this.contractInfo.noFeeBeginTime;
                                //t2 = this.getNewDataDays(t1, util.getMonthLastDay(t1)-1);
                                t2=util.dateFormat(util.dateAddDay(util.dateAddMonth(new Date(t1),1),-1))
                                total1 = t1 + "至" + t2;
                                //合同年限
                                t3 = t1;
                                t4 = this.getNewDataYears1(t3, 1);
                                total2 = t3 + "至" + t4;
                            }
                        } else {
                            if (
                                this.contractInfo.freeTimeType.value == config.FREE_PAY_INNTER
                            ) {
                                //免租期限
                                let t = this.contractInfo.noFeeBeginTime;
                                t1 = this.getNewDataYears2(t, i);
                                //t2 = this.getNewDataDays(t1, util.getMonthLastDay(t1)-1);
                                t2=util.dateFormat(util.dateAddDay(util.dateAddMonth(new Date(t1),1),-1))
                                total1 = t1 + "至" + t2;
                                //合同年限
                                t3 = t1;
                                t4 = this.getNewDataYears1(t3, 1);
                                total2 = t3 + "至" + t4;
                            }
                        }
                        this.freeRentArr[i] = {
                            num: i + 1,
                            noFeeMonthLong:1,
                            noFeeTimeLong: 0,//util.getMonthLastDay(t1),
                            timeFree: total1,
                            timeTotal: total2,
                            noFeeBeginTime: t1,
                            noFeeEndTime: t2,
                            everyYearEntrustBeginTime: t3,
                            everyYearEntrustEndTime: t4
                        };
                    }
                }
            },
            //监听租期
            "contractInfo.leaseTerm": function (val) {
                if(this.firstEdit){
                    this.firstEdit=false
                    return
                }
                if (val) {
                    var arr = new Array(parseInt(val));
                    let t1, t2, t3, t4, total1, total2;
                    if (parseInt(val) == 0) {
                        this.freeTimeTotal = 0;
                        this.freeMonthTotal=0
                        }
                    console.log(this.contractInfo.freeTimeType.value,this.contractInfo.noFeeBeginTime,"dsadsadsad=======")
                    for (let i = 0; i < arr.length; i++) {
                        if (i == 0) {
                            if (
                                this.contractInfo.freeTimeType.value == config.FREE_PAY_INNTER
                            ) {
                                //免租期限

                                t1 = this.contractInfo.noFeeBeginTime;
                                //t2 = this.getNewDataDays(t1, util.getMonthLastDay(t1)-1);
                                t2=util.dateFormat(util.dateAddDay(util.dateAddMonth(new Date(t1),1),-1))
                                total1 = t1 + "至" + t2;
                                //合同年限
                                t3 = t1;
                                t4 = this.getNewDataYears1(t3, 1);
                                total2 = t3 + "至" + t4;
                            }
                        } else {
                            if (
                                this.contractInfo.freeTimeType.value == config.FREE_PAY_INNTER
                            ) {
                                //免租期限
                                let t = this.contractInfo.noFeeBeginTime;
                                t1 = this.getNewDataYears2(t, i);
                                //t2 = this.getNewDataDays(t1, util.getMonthLastDay(t1)-1);
                                t2=util.dateFormat(util.dateAddDay(util.dateAddMonth(new Date(t1),1),-1))
                                total1 = t1 + "至" + t2;
                                //合同年限
                                t3 = t1;
                                t4 = this.getNewDataYears1(t3, 1);
                                total2 = t3 + "至" + t4;
                            }
                        }
                        arr[i] = {
                            num: i + 1,
                            noFeeMonthLong:1,
                            noFeeTimeLong: 0,//util.getMonthLastDay(t1),
                            timeFree: total1,
                            timeTotal: total2,
                            noFeeBeginTime: t1,
                            noFeeEndTime: t2,
                            everyYearEntrustBeginTime: t3,
                            everyYearEntrustEndTime: t4
                        };
                    }
                    this.freeRentArr = arr;
                    if(this.contractInfo.freeTimeType.value == config.FREE_PAY_OUT){
                        this.getCostLimit()
                    }

                }
            }
        },
        //如果前往的页面不是推荐人，则摧毁缓存
        beforeRouteLeave:function(to, from, next){
            if (from && to.name != 'referrer')
            {//此处判断是如果返回上一层，你可以根据自己的业务更改此处的判断逻辑，酌情决定是否摧毁本层缓存。
                if (this.$vnode && this.$vnode.data.keepAlive)
                {
                    if (this.$vnode.parent && this.$vnode.parent.componentInstance && this.$vnode.parent.componentInstance.cache)
                    {
                        if (this.$vnode.componentOptions)
                        {
                            var key = this.$vnode.key == null
                                ? this.$vnode.componentOptions.Ctor.cid + (this.$vnode.componentOptions.tag ? `::${this.$vnode.componentOptions.tag}` : '')
                                : this.$vnode.key;
                            var cache = this.$vnode.parent.componentInstance.cache;
                            var keys  = this.$vnode.parent.componentInstance.keys;
                            if (cache[key])
                            {
                                if (keys.length) {
                                    var index = keys.indexOf(key);
                                    if (index > -1) {
                                        keys.splice(index, 1);
                                    }
                                }
                                delete cache[key];
                            }
                        }
                    }
                }
                this.$destroy();
            }
            next();
        },

        methods: {
            toFindSubBank(){
                this.notFindSubBank=!this.notFindSubBank
                this.warnSubBank='未找到支行'==this.warnSubBank?'选择支行':'未找到支行'
            },
            selectSubBankList(){
                if(this.notFindSubBank){
                    return
                }
                this.$router.push({
                        name:'referrer',
                        query:{
                            type:'7',
                            titleName:'支行',
                            bankId:this.contractInfo.bankType.id,
                            cityCode:this.contractInfo.city.code

                    }
                })
            },
            chooseOffMaintain(){
                if(this.contractInfo.Latestleaseate=='0'){
                     return
                }
                if(this.contractInfo.LatestleaseateUp=='0'){
                  return
                }
                this.contractInfo.haveLatestleaseate='0'
            },
            chooseOnMaintain(){
                if(this.contractInfo.Latestleaseate=='0'){
                     return
                }
                if(this.contractInfo.LatestleaseateUp=='0'){
                    return
                }
                this.contractInfo.haveLatestleaseate='1'
            },
            //其他费用
            getQueryOwnerMaintenanceFund(){
                let that=this
                let queryData={}
                queryData.city_id=that.$route.params.city_id?that.$route.params.city_id:that.city_id  //潜房签约上页，续签在本页接口city_id
                that.contractInfo.otherFees=[]
                queryOwnerMaintenanceFund(queryData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        // console.log(response)
                        that.contractInfo.Latestleaseate=response.data.data.Latestleaseate  //其他费用开不开器
                        that.contractInfo.LatestleaseateUp=response.data.data.LatestleaseateUp   //其他费用是否可修改
                        if(that.contractInfo.Latestleaseate=='0'){
                            that.contractInfo.haveLatestleaseate=''
                        }else{
                            that.contractInfo.haveLatestleaseate='1'
                        }
                        let houseRoomCount = that.$route.params.houseRoomCount?that.$route.params.houseRoomCount:that.houseRoomCount  //这个房子有几间
                        that.contractInfo.ownerMaintenanceFund=response.data.data.ownerMaintenanceFund*(Math.ceil(houseRoomCount/that.houseRoomCountStandard))
                         that.newOwnerMaintenanceFund=response.data.data.ownerMaintenanceFund*(Math.ceil(houseRoomCount/that.houseRoomCountStandard))

                })
            })
        },
      //修改省份
      changeProvince(){
        for(let i in this.provinceArr){
            if(this.contractInfo.province.id==this.provinceArr[i].id){
                this.cityArr=this.provinceArr[i].childrens
                break
            }
        }
    },
    //查询全国所有省，市
    getProvinceAndCity(){
        let that=this
        let initData={}
        queryCityAndProvince(initData).then(function (response) {
            responseUtil.dealResponse(that, response, () => {
            that.provinceArr=response.data.data.province
             //that.initStaffData(2);
            })
        })
    },
        getCostLimit(){
           if(!this.contractInfo.paymentMethod.value){
               return
           }
           let initData={}
           let that=this
           initData.typePayment=that.contractInfo.paymentMethod.value
           initData.beginDateStr=that.contractInfo.noFeeBeginTime
           initData.monthsList=[]
           initData.daysList=[]
           for(let i in that.freeRentArr){
            initData.monthsList.push(that.freeRentArr[i].noFeeMonthLong?that.freeRentArr[i].noFeeMonthLong:0)
            initData.daysList.push(that.freeRentArr[i].noFeeTimeLong?that.freeRentArr[i].noFeeTimeLong:0)
           }
           ownercontractCycle(initData).then(function (response) {
            responseUtil.dealResponse(that, response, () => {
                that.costTimeLimit=response.data.data.date
                that.contractInfo.noFeeEndTime =that.costTimeLimit[that.costTimeLimit.length-1]
                that.contractInfo.noFeeEndTime=that.contractInfo.noFeeEndTime.replace(/\//g, '-')
                that.recount(response.data.data.date)

            })
        })
        },
        recount(data){
            let index=0
            for(let i in this.freeRentArr){
                for(let j=index;j<data.length;j++){
                    if(this.contractInfo.paymentMethod.value==0){  //月付
                        this.freeRentArr[i].timeFree=data[j].beginDate+'至'+data[j].endDate
                        this.freeRentArr[i].timeTotal=data[j].beginDate+'至'+data[j+12].endDate
                        this.freeRentArr[i].noFeeBeginTime=data[j].beginDate
                        this.freeRentArr[i].noFeeEndTime= data[j].endDate
                        this.freeRentArr[i].everyYearEntrustBeginTime=data[j].beginDate
                        this.freeRentArr[i].everyYearEntrustEndTime=data[j+12].endDate
                        this.freeRentArr[i].num=parseInt(i)+1
                        index+=13
                        break
                    }
                    else if(this.contractInfo.paymentMethod.value==1){  //季付
                        this.freeRentArr[i].timeFree=data[j].beginDate+'至'+data[j].endDate
                        this.freeRentArr[i].timeTotal=data[j].beginDate+'至'+data[j+4].endDate
                        this.freeRentArr[i].noFeeBeginTime=data[j].beginDate
                        this.freeRentArr[i].noFeeEndTime= data[j].endDate
                        this.freeRentArr[i].everyYearEntrustBeginTime=data[j].beginDate
                        this.freeRentArr[i].everyYearEntrustEndTime=data[j+4].endDate
                        this.freeRentArr[i].num=parseInt(i)+1
                        //this.freeRentArr[i].num=parseInt(i)+1
                        index+=5
                        break
                    }
                    else if(this.contractInfo.paymentMethod.value==2){  //半年付
                        this.freeRentArr[i].timeFree=data[j].beginDate+'至'+data[j].endDate
                        this.freeRentArr[i].timeTotal=data[j].beginDate+'至'+data[j+2].endDate
                        this.freeRentArr[i].noFeeBeginTime=data[j].beginDate
                        this.freeRentArr[i].noFeeEndTime= data[j].endDate
                        this.freeRentArr[i].everyYearEntrustBeginTime=data[j].beginDate
                        this.freeRentArr[i].everyYearEntrustEndTime=data[j+2].endDate
                        this.freeRentArr[i].num=parseInt(i)+1
                        index+=3
                        break
                    }
                    else if(this.contractInfo.paymentMethod.value==3){  //年付
                        this.freeRentArr[i].timeFree=data[j].beginDate+'至'+data[j].endDate
                        this.freeRentArr[i].timeTotal=data[j].beginDate+'至'+data[j+1].endDate
                        this.freeRentArr[i].noFeeBeginTime=data[j].beginDate
                        this.freeRentArr[i].noFeeEndTime= data[j].endDate
                        this.freeRentArr[i].everyYearEntrustBeginTime=data[j].beginDate
                        this.freeRentArr[i].everyYearEntrustEndTime=data[j+1].endDate
                        this.freeRentArr[i].num=parseInt(i)+1
                        index+=2
                        break
                    }
                    else if(this.contractInfo.paymentMethod.value==4){  //一次性付请
                        this.freeRentArr[i].timeFree=data[j].beginDate+'至'+data[j].endDate
                        index+=1
                        if(i==this.freeRentArr.length-1){
                            this.freeRentArr[i].timeTotal=data[j].beginDate+'至'+data[data.length-1]
                            this.freeRentArr[i].everyYearEntrustEndTime=data[data.length-1]
                        }else{
                            this.freeRentArr[i].timeTotal=data[j].beginDate+'至'+util.dateFormat(util.dateAddDay(data[j+1].beginDate,-1))
                            this.freeRentArr[i].everyYearEntrustEndTime=util.dateFormat(util.dateAddDay(data[j+1].beginDate,-1))
                        }
                        this.freeRentArr[i].noFeeBeginTime=data[j].beginDate
                        this.freeRentArr[i].noFeeEndTime= data[j].endDate
                        this.freeRentArr[i].everyYearEntrustBeginTime=data[j].beginDate
                        this.freeRentArr[i].num=parseInt(i)+1
                        break
                    }
                }
            }
            this.$forceUpdate()

        },
            initStaffData(type) {
                var that = this;
                let initData = {};
                if(type == 2){
                    initData.id = getStaffId()
                }
                queryStaffList(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.signatoryArr = response.data.data.data;
                        let signatory = {}
                      for (let i = 0; i < that.signatoryArr.length; i++) {
                        if(that.signatoryArr[i].id == getStaffId()){
                          signatory = that.signatoryArr[i]
                        }
                      }
                        that.partnerArr = util.deepClone(that.signatoryArr)
                        that.partnerArr.unshift({
                            department_id: '-1',
                            id: '',
                            staffName: "无",
                            title: "无",
                            value: -1
                        });
                        // 因为changeContractInit 用到 signatiryArr 所以放在这里
                        if(type!=2){
                            that.changeContractInit();
                        }
                        if(that.$route.params.flagType!=1){    //flagType=1为修改,2为续租,''为新签
                            that.contractInfo.signatory = signatory
                            that.getQueryOwnerMaintenanceFund()
                        }
                    });
                });
            },
            // 手机号隐藏四位
            getTel(tel) {
                var reg = /^(\d{3})\d{4}(\d{4})$/;
                return tel.replace(reg, "$1****$2");
            },
            propertyRightAddressd(){
              if(!this.propertyRightAddress){
                  responseUtil.alertMsg(this,'产权地址格式不正确')
              }
            },
            initData() {
                let that = this;
                // 查询常量
                var ratio = config.DICTIONARY.PARTNER_RATIO;
                var bank = config.DICTIONARY.BANK;
                var propertyRight = config.DICTIONARY.PROPERTY_RIGHT_TYPE;
                queryBaseData({
                    dbName: [
                        'billFeeType', 'billPayType', 'payType', ratio,
                        'houseOrientation', bank, propertyRight, 'contractLabel',
                        'heatingMode', 'mortgageSituation','savingsCertificate'
                    ],
                    fdName: [
                        'CYCLETYPEMAP', 'CERTIFICATETYPEMAP', 'CYCLETYPEMAPOTHER', 'CONTRACTSIGNTYPE',
                        'STAFFGENDERMAP', 'NOFEETYPEMAP'
                    ]
                }).then(function (response) {
                    var lists = response.data.data;
                    for (var key in lists) {
                        var values = lists[key];
                        for (var i = 0, item; item = values[i++];) {
                            item.value = item.id || item.dictionaryValue;
                            item.label = item.dictionaryTitle;
                        }
                    }
                    // 银行类型
                    that.bankTypeArr = lists.bankList;
                    //储蓄类型
                    that.savingsCertificateList = lists.savingsCertificate
                    // 纸质合同
                    that.contractLabel = lists.contractLabel != undefined ? lists.contractLabel : [];
                    // 占比
                    that.cooperationTypeArr = lists.contractRaito;
                    // 供暖
                    that.heatingTypeArr = lists.heatingMode;
                    // 抵押
                    that.mortgageTypeArr = lists.mortgageSituation;
                    // 权属类型
                    that.ownershipTypeArr = lists.propertyRightType;
                    // 朝向
                    that.toWhereTypeArr = lists.houseOrientation;
                    // 月付 季付
                    that.paymentMethodArr = lists.CYCLETYPEMAP;
                    // 证件类型
                    that.certificateTypeArr = lists.CERTIFICATETYPEMAP;
                    // 内置or外置
                    that.freeTimeTypeArr = lists.NOFEETYPEMAP;
                });
                // 员工列表初始化
                that.initStaffData(1);
                // 查询推荐人
                that.initReferrer();
                // that.findUserInfoByMobile(true,false,'15043490232')
            },
            initHouseRoomCount(){
              let that = this
              let queryData = {}
              queryData.name = 'houseRoomCount'
              policyDecisionData(queryData).then(function (response) {   //决策
                that.houseRoomCountStandard = response.data.data.policyDecisionName  //标准间数
              })
            },
            //推荐人查询
            initReferrer(){
                let that = this;
                let data = {};
                if(!that.referrerText||that.referrerText.length <11){
                    return
                }
                data.searchName = that.referrerText;
                data.partnerTransfer= that.contractInfo.partner.id
                data.addStaff_id=getStaffId()
                //seeWithUserList
                remoteMobileSearch(data).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        console.log(response)
                        that.partnershipProportionArr = response.data.data.data;
                        if(that.partnershipProportionArr){

                            that.partnershipProportionArr.forEach(item => {
                                item.label = item.userName //+ " " + that.getTel(item.mobile);
                                console.log(that.contractInfo.partnershipProportion)
                                that.contractInfo.partnershipProportion={}
                                that.contractInfo.partnershipProportion.label=item.label
                            });
                        }
                    });
                });
            },

            createContractCode(){
                let that = this
                // 创建合同编号
                createContractCode({
                    type: "0"
                }).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        if(that.flagType == "0"){
                            that.contractInfo.number = response.data.data.contractCode;
                        }
                        that.contractInfo.newNumber = response.data.data.contractCode;
                    });
                });
            },
            // 新增或者 修改合同初始化
            changeContractInit() {
                let that = this
                that.flagType = that.$route.params.flagType
                if (that.flagType == "1") {
                    this.firstEdit=true
                    this.secondEdit=true
                    this.thirdEdit=true
                    that.title = '修改合同'
                    that.contractInfo.id = that.$route.params.ownerContractId
                    let initData = {
                        "ownerContractId": that.contractInfo.id,
                        "flagType": that.flagType
                    }
                    ownercontractfindOwnerContractInfo(initData).then(function (response) {
                        responseUtil.dealResponse(that, response, () => {
                            let data = response.data.data.data;
                            setTimeout(that.callBackShow(data), 0);
                        });
                    });
                }else if(that.flagType == 0){
                    that.title = '新增合同'
                    that.ownerInfo.estateRoom_id = that.$route.params.estateRoom_id
                    that.ownerInfo.potentialResource_id = that.$route.params.prId
                    that.houseAddress = that.$route.params.address
                    that.createContractCode();
                    that.findUserInfoByMobile(true, false, that.$route.params.mobile)
                    that.contractInfo.signedType = that.setSelectObj(that.ownerTypeArr,"0")
                } else {
                    that.title = '录入合同'
                    that.contractInfo.type = '续签'
                    that.createContractCode();
                    // that.findUserInfoByMobile(true, false, that.$route.params.mobile)
                    that.ownerInfo.potentialResource_id = that.$route.params.prId
                    // that.houseAddress = that.$route.params.houseAddress
                    that.ownerInfo.estateRoom_id = that.$route.params.estateRoom_id
                    that.contractInfo.signedType = that.setSelectObj(that.ownerTypeArr,"0")
                    //that.contracthistoryId = that.$route.params.contracthistoryId
                    that.contractInfo.id = that.$route.params.ownerContractId
                    let changeInfoParam = that.$route.params.changeInfoParam
                    changeInfoParam.changeType = 2
                    that.terminateInfoParam.push(changeInfoParam)
                    let initData = {
                        "ownerContractId": that.contractInfo.id,
                        "flagType": that.flagType
                    }
                    ownercontractfindOwnerContractInfo(initData).then(function (response) {
                        responseUtil.dealResponse(that, response, () => {
                            let data = response.data.data.data;

                            setTimeout(that.callBackShow(data), 0);
                        });
                    });


                }
                // }
            },
            //查询用户实名认证,只有业主和代理人需要实名认证
            findUserInfoByMobile: function (isOwner, isAgent, mobile) {
            let that = this
            let initData = {}
            initData.userMobile = mobile
                findUserInfoByMobile(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        let result = response.data.data.data
                        let phont = response.data.data.data.photo
                        let nameAuthentication = 0
                        if (result && result.nameAuthentication) {
                            nameAuthentication = result.nameAuthentication
                        }
                        //业主实名认证结果处理
                        if (isOwner) {
                            that.ownerInfo.ownerNameAuthentication = nameAuthentication
                            if (result && result.id) {
                                that.ownerInfo.name = that.ownerInfo.name|| result.userName
                                that.ownerInfo.Id = result.id
                                that.ownerInfo.sex =  that.ownerInfo.sex|| result.gender + ''
                                if(result.certificateType){
                                    that.ownerInfo.certificateType =that.setSelectObj(that.certificateTypeArr, result.certificateType)
                                }
                                that.ownerInfo.certificateCode = that.ownerInfo.certificateCode || result.certificateCode
                                that.ownerInfo.phone = mobile
                                that.isOwnerFlag = true;
                                // that.animateOwnerIdCard()

                            }

                            if (result.fpth) {
                                that.ownerInfo.yzFrontOfCard = result.fpth
                            } else {
                                that.ownerInfo.yzFrontOfCard = require('../../../assets/images/default_img_4.png')
                            }
                            if (result.bpth) {
                                that.ownerInfo.yzReverseOfCard = result.bpth
                            } else {
                                that.ownerInfo.yzReverseOfCard = require('../../../assets/images/default_img_5.png')
                            }
                            if (result.hpth) {
                                that.ownerInfo.yzHoldCard = result.hpth
                            } else {
                                that.ownerInfo.yzHoldCard = require('../../../assets/images/default_img_3.png')
                            }

                            if(phont && phont.length>0){
                                for(let i=0;i<phont.length;i++){
                                    let photodetail = phont[i]
                                    if(photodetail.contractPhotoType == 0){
                                        that.ownerInfo.yzFrontOfCard = photodetail.path
                                    }else if(photodetail.contractPhotoType == 1){
                                        that.ownerInfo.yzReverseOfCard = photodetail.path
                                    }else if(photodetail.contractPhotoType == 2){
                                        that.ownerInfo.yzHoldCard = photodetail.path
                                    }
                                }
                            }

                        }
                        //代理人实名认证结果处理
                        else if (isAgent) {
                            that.proxyInfo.agentNameAuthentication = nameAuthentication
                            if (result && result.id) {
                                that.proxyInfo.agentName = that.proxyInfo.agentName ||result.userName
                                that.proxyInfo.agentId = result.id
                                that.proxyInfo.agentGender =that.proxyInfo.agentGender || result.gender
                                that.proxyInfo.agentCertificateCode = that.proxyInfo.agentCertificateCode|| result.certificateCode
                                if(result.certificateType){
                                    that.proxyInfo.certificateType = that.setSelectObj(that.certificateTypeArr, result.certificateType)
                                }
                            }
                            that.isOwnerFlag = false;
                        }
                        that.setOwnerEditState()
                    })
                })
            },
            /**
             * 设置业主信息编辑状态
             * 上上签实名认证的用户不能编辑,没有实名认证才可以编辑
             */
            setOwnerEditState: function () {
                let that = this
                if (that.ownerInfo.ownerNameAuthentication == 1 && that.isChooseProxy == false) {
                    that.setOwnerState(true)
                } else {
                    that.setOwnerState(false)
                    that.changeCertificateType(that.ownerInfo.ownerCertificateType);
                }
                if (that.proxyInfo.agentNameAuthentication == 1) {
                    that.setAgentState(true)
                } else {
                    that.setAgentState(false)
                }
            },
            //选择证件类型
            changeCertificateType: function (val) {
                let that = this
                //选择身份证时不能选择性别,通过身份证号判断性别
                if (val == 0) {
                    that.ownerInfo.sexState = true
                } else {
                    that.ownerInfo.sexState = false
                }
            },
            //设置业主信息编辑状态
            setOwnerState: function (owner) {
                let that = this
                // that.ownerInfo.mobileState = owner
                that.ownerInfo.sexState = owner
                that.ownerInfo.certificateState = owner
                that.ownerInfo.nameState = owner
            },
            //设置代理人编辑状态
            setAgentState: function (agent) {
                let that = this
                that.proxyInfo.agentSexState = agent
                that.proxyInfo.agentNameState = agent
                that.proxyInfo.agentCertificateState = agent
            },
            // 计算账单
            createBill: function () {
                var me = this;
                var formValidate = me.contractInfo;
                var freePayList = me.freeRentArr;
                for(let i in freePayList){
                    if(!freePayList[i].num){
                        freePayList[i].num=Number(i)+1
                    }
                }
                console.log(freePayList)
                var otherBills = [];
                if (me.contractInfo.otherFees.length > 0) {

                    // 房租
                    let createDate = new Date(me.commonDateFormate(formValidate.giveHouseTime));
                    createDate.setDate(createDate.getDate() + 3);
                    for (let i = 0; i < freePayList.length; i++) {
                        let item = freePayList[i];
                        // 费用周期开始时间为当年开始时间加上免租期
                        let freeDays = item.num;
                        let noFeeNum = freePayList[i].noFeeTimeLong;
                        let noFeeMonth=freePayList[i].noFeeMonthLong
                        let begin = new Date(me.commonDateFormate(item.everyYearEntrustBeginTime));
                        let end = new Date(me.commonDateFormate(item.everyYearEntrustEndTime));
                        otherBills  = otherBills.concat(
                            util.creatBills(
                                i == 0 ? createDate : begin,
                                begin,
                                // util.dateAddDay(begin, freeDays),
                                end,
                                [{
                                    billCollectionPaymentType: "收", // 收支状态
                                    costtype: '11',
                                    noFeeType: formValidate.freeTimeType.value,
                                    noFeeMonth:noFeeMonth,
                                    noFeeNum: noFeeNum,
                                    oldNoFeeNum: util.getMonthLastDay(item.noFeeBeginTime),
                                    costtypeVal: "服务费",
                                    payTypeId: '3',
                                    payUnitType: '2',
                                    totalamount: Number(me.contractInfo.ownerMaintenanceFund)?Number(me.contractInfo.ownerMaintenanceFund):0,
                                    count: formValidate.leaseTerm,
                                    nowCount: freeDays,
                                    identity: "owner", // owner业主,tenant租客
                                    typeFlag: "1",
                                    describe: "维修基金"
                                }],
                                config.ADVANCE_DAYS
                            )
                        )
                        for (var j = 0; j < otherBills .length; j++) {
                            otherBills [j].type='otherFees'
                        }
                    }
                }
                var renterMoneyBill = [];
                // 是否一次性付清
                if (formValidate.paymentMethod.value == config.PAY_TYPE_ONCE) {
                    // 房租
                    let noFeeNum = 0;
                    let noFeeMonth=0
                    for (var i = 0; i < freePayList.length; i++) {
                        let item = freePayList[i].noFeeTimeLong;
                        noFeeNum += parseInt(item);
                        noFeeMonth+=parseInt(freePayList[i].noFeeMonthLong)
                    }
                    let freeDays = 1;
                    let begin = new Date(me.commonDateFormate(formValidate.noFeeBeginTime));
                    let end = new Date(me.commonDateFormate(formValidate.noFeeEndTime));
                    let createDate = new Date(me.commonDateFormate(formValidate.giveHouseTime));
                    createDate.setDate(createDate.getDate() + 3);
                    renterMoneyBill = renterMoneyBill.concat(
                        util.creatBills(
                            createDate,
                            util.dateAddDay(begin, freeDays),
                            end,
                            [{
                                billCollectionPaymentType: "支", // 收支状态
                                costtype: config.COST_TYPE_OF_RENT,
                                noFeeType: formValidate.freeTimeType.value,
                                noFeeMonth:noFeeMonth,
                                noFeeNum: noFeeNum,
                                oldNoFeeNum: 0,
                                nowCount: 1,
                                costtypeVal: "房租",
                                payTypeId: formValidate.paymentMethod.value,
                                payUnitType: config.PAY_UNIT_MONTH,
                                totalamount: formValidate.rent,
                                count: formValidate.leaseTerm,
                                identity: "owner", // owner业主,tenant租客
                                typeFlag: "2",
                                describe: "房租"
                            }],
                            config.ADVANCE_DAYS
                        )
                    );
                } else {
                    // 房租
                    let createDate = new Date(me.commonDateFormate(formValidate.giveHouseTime));
                    createDate.setDate(createDate.getDate() + 3);
                    for (let i = 0; i < freePayList.length; i++) {
                        let item = freePayList[i];
                        // 费用周期开始时间为当年开始时间加上免租期
                        let freeDays = item.num;
                        let noFeeNum = freePayList[i].noFeeTimeLong;
                        let noFeeMonth=freePayList[i].noFeeMonthLong
                        let begin = new Date(me.commonDateFormate(item.everyYearEntrustBeginTime));
                        let end = new Date(me.commonDateFormate(item.everyYearEntrustEndTime));
                        renterMoneyBill = renterMoneyBill.concat(
                            util.creatBills(
                                i == 0 ? createDate : begin,
                                begin,
                                // util.dateAddDay(begin, freeDays),
                                end,
                                [{
                                    billCollectionPaymentType: "支", // 收支状态
                                    costtype: config.COST_TYPE_OF_RENT,
                                    noFeeType: formValidate.freeTimeType.value,
                                    noFeeMonth:noFeeMonth,
                                    noFeeNum: noFeeNum,
                                    oldNoFeeNum: util.getMonthLastDay(item.noFeeBeginTime),
                                    costtypeVal: "房租",
                                    payTypeId: formValidate.paymentMethod.value,
                                    payUnitType: config.PAY_UNIT_MONTH,
                                    totalamount: formValidate.rent,
                                    count: formValidate.leaseTerm,
                                    nowCount: freeDays,
                                    identity: "owner", // owner业主,tenant租客
                                    typeFlag: "2",
                                    describe: "房租"
                                }],
                                config.ADVANCE_DAYS
                            )
                        );
                    }
                }
                var depositMoneyBill = [];
                // 押金
                /*if (formValidate.deposit != "" && formValidate.deposit > 0) {
                    let createDate = new Date(me.commonDateFormate(formValidate.giveHouseTime));
                    createDate.setDate(createDate.getDate() + 3);
                    depositMoneyBill = util.creatBills(
                        createDate,
                        formValidate.noFeeBeginTime,
                        formValidate.noFeeEndTime,
                        [{
                            billCollectionPaymentType: "支", // 收支状态
                            costtype: config.COST_TYPE_OF_DEPOSIT,
                            costtypeVal: "押金",
                            noFeeType: formValidate.freeTimeType.value,
                            noFeeMonth:0,
                            noFeeNum: 0,
                            oldNoFeeNum: 0,
                            nowCount: 1,
                            payTypeId: config.PAY_TYPE_ONCE,
                            payUnitType: config.PAY_UNIT_MONTH,
                            totalamount: formValidate.deposit,
                            count: formValidate.leaseTerm,
                            identity: "owner", // owner业主,tenant租客
                            typeFlag: "3",
                            describe: "押金"
                        }],
                        config.ADVANCE_DAYS
                    );
                    depositMoneyBill = depositMoneyBill.concat(
                        util.creatBills(
                            formValidate.noFeeEndTime,
                            formValidate.noFeeBeginTime,
                            formValidate.noFeeEndTime,
                            [{
                                billCollectionPaymentType: "收", // 收支状态
                                costtype: config.COST_TYPE_OF_DEPOSIT,
                                costtypeVal: "押金",
                                noFeeType: formValidate.freeTimeType.value,
                                noFeeMonth:0,
                                noFeeNum: 0,
                                oldNoFeeNum: 0,
                                nowCount: 1,
                                payTypeId: config.PAY_TYPE_ONCE,
                                payUnitType: config.PAY_UNIT_MONTH,
                                totalamount: formValidate.deposit,
                                count: formValidate.leaseTerm,
                                identity: "owner", // owner业主,tenant租客
                                typeFlag: "3",
                                describe: "押金"
                            }],
                            config.ADVANCE_DAYS
                        )
                    );
                }*/
                let list = renterMoneyBill.concat(depositMoneyBill).concat(otherBills);
                for (let i = 0; i < list.length; i++) {
                    list[i].billCollectionPaymentTypeVal = util.getPayDirectionLabel(
                        list[i].billCollectionPaymentType
                    );
                    if (list[i].costBeginDate && list[i].costEndDate) {
                        list[i].range = list[i].costBeginDate + "至" + list[i].costEndDate;
                    } else {
                        list[i].range = "";
                    }
                }
                var compare = function (obj1, obj2) {
                    var val1 = obj1.collectionDate;
                    var val2 = obj2.collectionDate;
                    if (val1 < val2) {
                        return -1;
                    } else if (val1 > val2) {
                        return 1;
                    } else {
                        return 0;
                    }
                };
                return list//.sort(compare);
            },
            /*
            说明:角色下拉框change事件
            选择代理权人时,清空业主信息,并将业主信息复制到代理人信息,选择代理人信息checkbox显示代理人信息
            选择回业主时,清空代理人信息,取消代理人信息checkbox选择,并将信息复制到业主信息
            */
            signRoleTypeChange: function (id) {
                if (id == '0') {
                    // 业主
                    this.isChooseProxy = false
                    this.ownerInfo.mobileState = true
                    this.proxyInfo.agentMobileState = false
                    this.ownerInfo.phone = this.proxyInfo.phone
                    this.ownerInfo.name = this.proxyInfo.name
                    this.ownerInfo.certificateCode = this.proxyInfo.certificateCode
                    this.ownerInfo.certificateType = this.proxyInfo.certificateType
                    this.proxyInfo.phone = ''
                    this.proxyInfo.name = ''
                    this.proxyInfo.certificateCode = ''
                    this.proxyInfo.certificateType = ''
                    //业主图片得根据业主实名状态判断 如果实名了图片去查，如果没实名图片还需返回
                    if(this.ownerInfo.ownerNameAuthentication==1){
                        this.findUserInfoByMobile(true, false, this.ownerInfo.phone)
                    } else {
                        //回显
                        this.ownerInfo.yzFrontOfCard = this.proxyInfo.wtrFrontOfCard
                        this.ownerInfo.yzReverseOfCard = this.proxyInfo.wtrReverseOfCard
                        this.ownerInfo.yzHoldCard = this.proxyInfo.cachePhoto
                    }
                    this.proxyInfo.wtrFrontOfCard = require('../../../assets/images/default_img_4.png')//身份证正面
                    this.proxyInfo.wtrReverseOfCard = require('../../../assets/images/default_img_5.png')//身份证反面
                    this.proxyInfo.entrustment = require('../../../assets/images/default_img_6.png') //业主委托
                    this.proxyInfo.holdEntrustment = require('../../../assets/images/default_img_6.png') //业主手持委托
                    this.isOwnerFlag = true
                    this.proxyRadio = '0'   //代理人信息 控制是否
                } else {
                    // 代理人
                    this.isChooseProxy = true
                    this.proxyRadio = '1',   //代理人信息 控制是否
                    this.ownerInfo.mobileState = false
                    this.proxyInfo.agentMobileState = true
                    this.proxyInfo.phone = this.ownerInfo.phone
                    this.proxyInfo.name = this.ownerInfo.name
                    this.proxyInfo.certificateCode = this.ownerInfo.certificateCode
                    this.proxyInfo.certificateType = this.ownerInfo.certificateType
                    this.ownerInfo.phone = ''
                    this.ownerInfo.name = ''
                    this.ownerInfo.certificateCode = ''
                    this.ownerInfo.certificateType = ''
                    this.isOwnerFlag = false //如果是代理人，则代理人手机不能修改
                    this.proxyInfo.wtrFrontOfCard = this.ownerInfo.yzFrontOfCard//身份证正面
                    this.proxyInfo.wtrReverseOfCard = this.ownerInfo.yzReverseOfCard//身份证反面
                    this.proxyInfo.cachePhoto = this.ownerInfo.yzHoldCard
                    this.ownerInfo.yzFrontOfCard = require('../../../assets/images/default_img_4.png')
                    this.ownerInfo.yzReverseOfCard = require('../../../assets/images/default_img_5.png')
                    this.ownerInfo.yzHoldCard = require('../../../assets/images/default_img_3.png')
                    //alert( this.ownerInfo.nameState)
                    this.ownerInfo.nameState = false  //如果选择代理人签约，则业主姓名可以填写
                    this.ownerInfo.certificateState = false  //如果选择代理人签约，则业主姓名可以填写
                }
                this.setOwnerEditState()
            },

            //是否有代理人值变化时
            isChoosePeoxyPerson(id){   //0否  1是
                if(id==1){
                    //控制代理人信息“是”“否”显示
                    this.isChooseProxy = true
                }else if(id==0){
                    this.isChooseProxy = false
                }
            },

            //公共转换日期转换格式(YYYY-MM-DD转换为YYYY/MM/DD适应ios系统)
            commonDateFormate(time){
                var arr = time.split("-");
                var nDate = new Date(arr[1] + "/" + arr[2] + "/" + arr[0]);
                var year = nDate.getFullYear();
                var month = nDate.getMonth() + 1;
                if (month < 10) month = "0" + month;
                var date = nDate.getDate();
                if (date < 10) date = "0" + date;
                return year + "-" + month + "-" + date;
            },


            //监听业主手机号
            animatePhone() {
                var re = config.MOBILE_REG
                let str = this.ownerInfo.phone;
                if (!re.test(str)) {
                    responseUtil.alertMsg(this,'业主手机号格式不正确')
                    // this.ownerInfo.phone = "";
                }
            },
            //监听紧急联系人手机号
            animateEmPhone() {
                var re = config.MOBILE_REG
                let str = this.ownerInfo.emergencyContactPhone;
                if (str != '' && !re.test(str)) {
                    responseUtil.alertMsg(this,'紧急联系人手机号格式不正确')
                    // this.ownerInfo.emergencyContactPhone = "";
                }
            },
            //监听银行卡号
            chickBankCard() {
                //如果是存折不校验银行卡号
                // if(this.bankTypeInfo.value != undefined&&this.bankTypeInfo.value!=374){
                //     return
                // }
                let str = this.ownerInfo.bankCard;
                if(this.bankTypeInfo.value == undefined || this.bankTypeInfo.value==374){
                    if (str != '' && !util.luhnCheck(str)) {
                        responseUtil.alertMsg(this,'收款银行卡卡号不正确')
                        // this.ownerInfo.bankCard = "";
                    }
                }
            },
            // 检验名字
            checkName(objName,val) {
                if (!val) {
                    //格式错误输入的内容不清空
                   switch (objName) {
                       case 'propertyRightAddress':
                           responseUtil.alertMsg(this,'产权地址不能为空')
                           break;
                       case 'ownerInfo':
                           responseUtil.alertMsg(this,'姓名不能为空')
                           break;
                       case 'emergencyContactName':
                           responseUtil.alertMsg(this,'姓名不能为空')
                           break;
                       case 'receiver':
                           responseUtil.alertMsg(this,'姓名不能为空')
                           break;
                       case 'proxyInfo':
                           if(this.isChooseProxy){
                               responseUtil.alertMsg(this,'姓名不能为空')
                           }
                           break;
                       case 'coOwner':
                           if(this.isChooseCoOwner){
                               responseUtil.alertMsg(this,'姓名不能为空')
                           }
                           break;
                       default:
                           break;
                   }
                }
            },
            //监听代理人手机号
            animatePoPhone() {
                var re = config.MOBILE_REG
                let str = this.proxyInfo.phone;
                if (!re.test(str)) {
                    responseUtil.alertMsg(this,'代理人手机号格式不正确')
                    // this.proxyInfo.phone = "";
                }
            },
            //监听共有权人手机号
            animateCoPhone() {
                var re = config.MOBILE_REG
                let str = this.coOwner.phone;
                if (!re.test(str)) {
                    responseUtil.alertMsg(this,'共有权人手机号格式不正确')
                    // this.coOwner.phone = "";
                }
            },
            //监听邮箱格式
            animateEmail() {
                var re = config.EMAIL_REG
                let str = this.ownerInfo.email;
                if (str != '' && !re.test(str)) {
                    responseUtil.alertMsg(this,'邮箱格式不正确')
                    // this.ownerInfo.email = "";
                }
            },
            //监听业主身份证信息(20200821方树成修改)
            animateOwnerIdCard() {
                var re = config.CERTIFICATE_REG
                let str = this.ownerInfo.certificateCode
                if(this.ownerInfo.certificateType.value == 0 || this.ownerInfo.certificateType.value== undefined){
                    if (str!= '' && !re.test(str)) {
                        responseUtil.alertMsg(this,'业主身份证格式不正确')
                        // this.ownerInfo.certificateCode = "";
                    }else{
                        this.ownerInfo.sex = (str.substr(-2, 1) % 2) + ""
                    }
                }

            },
            //监听代理人人身份证信息
            animateProxyIdCard() {
                var re = config.CERTIFICATE_REG
                let str = this.proxyInfo.certificateCode;
                if(this.proxyInfo.certificateType.value == 0 || this.proxyInfo.certificateType.value== undefined){
                this.proxyInfo.sex = (str.substr(-2, 1) % 2) + ""
                if (str!= '' && !re.test(str)) {
                    responseUtil.alertMsg(this,'代理人身份证格式不正确')
                    //this.proxyInfo.certificateCode = "";
                }
                }
            },
            //监听共有权人身份证信息
            animateCoIdCard() {
                var re = config.CERTIFICATE_REG
                if(this.coOwner.certificateType.value == 0 || this.coOwner.certificateType.value == undefined){
                let str = this.coOwner.IdCard;
                    if (!re.test(str)) {
                        responseUtil.alertMsg(this,'共有权人身份证格式不正确')
                        //this.coOwner.IdCard = "";
                    }
                }
            },
            formatter(value) {
                // 过滤空格
                return value.replace(/\s*/g,"");
            },
            //监听遍历出来的input框，动态改变数组的属性
            changeFree: function (index, item,type) {
                if (item) {
                    this.getNewArray(index, item,type);
                } else {
                    this.getNewArray(index, 0,type);
                }

                this.freeTimeTotal = 0;
                this.freeMonthTotal=0
                this.getTotalFreeTime();
            },
            //公共方法传一个下标和一个天数改变数组的所有值返回一个新数组
            getNewArray(index, item,type) {
                if (this.contractInfo.freeTimeType.value == config.FREE_PAY_INNTER) {
                    //内置算法
                    let t1, t2, t3, t4, total1, total2;
                    for (let i = index; i < this.freeRentArr.length; i++) {
                        if(type=='month'&&!this.freeRentArr[i].noFeeMonthLong){
                             this.freeRentArr[i].noFeeMonthLong=0
                        }
                        if (index == 0) {
                            t1 = this.contractInfo.noFeeBeginTime;
                            //t2 = this.getNewDataDays(t1, parseInt(item)-1);
                            t2=util.dateFormat(util.dateAddDay(util.dateAddMonth(new Date(t1),this.freeRentArr[i].noFeeMonthLong),Number(this.freeRentArr[i].noFeeTimeLong)-1))
                            total1 = t1 + "至" + t2;
                            t3 = t1;
                            t4 = this.getNewDataYears1(t3, 1);
                            total2 = t3 + "至" + t4;
                            this.freeRentArr[index].timeFree = total1;
                            this.freeRentArr[index].timeTotal = total2;        //每年合同期限
                            this.freeRentArr[index].noFeeBeginTime = t1;   //每年免租期开始时间
                            this.freeRentArr[index].noFeeEndTime = t2;        //每年免租期结束时间
                            this.contractInfo.noFeeEndTime=this.freeRentArr[this.freeRentArr.length-1].everyYearEntrustEndTime
                            this.freeRentArr[index].num=1
                            return this.freeRentArr;
                        } else {
                            let t = this.contractInfo.noFeeBeginTime;
                            t1 = this.getNewDataYears2(t, index);
                            //t2 = this.getNewDataDays(t1, parseInt(item)-1);
                            t2=util.dateFormat(util.dateAddDay(util.dateAddMonth(new Date(t1),this.freeRentArr[i].noFeeMonthLong),Number(this.freeRentArr[i].noFeeTimeLong)-1))
                            total1 = t1 + "至" + t2;
                            t3 = t1;
                            t4 = this.getNewDataYears1(t3, 1);
                            total2 = t3 + "至" + t4;
                            this.freeRentArr[index].timeFree = total1;
                            this.freeRentArr[index].timeTotal = total2;
                            this.freeRentArr[index].noFeeBeginTime = t1;   //每年免租期开始时间
                            this.freeRentArr[index].noFeeEndTime = t2;        //每年免租期结束时间
                             this.freeRentArr[index].num=parseInt(index)+1
                    this.contractInfo.noFeeEndTime=this.freeRentArr[this.freeRentArr.length-1].everyYearEntrustEndTime
                            return this.freeRentArr;
                        }
                    }

                } else {
                    //外置算法
                    this.getCostLimit()
                    /*let t1, t2, t3, t4, total1, total2;
                    for (let i = index; i < this.freeRentArr.length; i++) {
                        if(type=='month'&&!this.freeRentArr[i].noFeeMonthLong){
                             this.freeRentArr[i].noFeeMonthLong=0
                        }
                        if (index == 0) {
                            if (i == 0) {
                                t1 = this.contractInfo.noFeeBeginTime;
                                //t2 = this.getNewDataDays(t1, parseInt(item)-1);
                                t2=util.dateFormat(util.dateAddDay(util.dateAddMonth(new Date(t1),this.freeRentArr[i].noFeeMonthLong),Number(this.freeRentArr[i].noFeeTimeLong)-1))
                                total1 = t1 + "至" + t2;
                                t3 = t1;
                                // if(this.textIsDays(t3)){
                                //     t4 = this.getNewDataYears1(t3, 1);
                                // } else {
                                //     t4 = this.getNewDataYears2(t3, 1);
                                // }
                                // t4 = this.getNewDataDays(t4, parseInt(item)-1);
                                t4 = this.getNewDataYears2(t2, 1);
                                total2 = t3 + "至" + t4;
                                this.freeRentArr[0].timeFree = total1;
                                this.freeRentArr[0].timeTotal = total2;
                                this.freeRentArr[0].noFeeBeginTime = t1;
                                this.freeRentArr[0].noFeeEndTime = t2;
                            } else {
                                //以后每一年都和上一年相关
                                let t = this.freeRentArr[i - 1].timeTotal.split("至")[1];
                                console.log(t)
                                t1 = this.getNewDataDays(t, 1);
                                //t2 = this.getNewDataDays(t1, parseInt(this.freeRentArr[i].noFeeTimeLong)-1);
                                t2=util.dateFormat(util.dateAddDay(util.dateAddMonth(new Date(t1),this.freeRentArr[i].noFeeMonthLong),Number(this.freeRentArr[i].noFeeTimeLong)-1))
                                total1 = t1 + "至" + t2;
                                t3 = t1;
                                // if(this.textIsDays(t3)){
                                //     t4 = this.getNewDataYears1(t3, 1);
                                // } else {
                                //     t4 = this.getNewDataYears2(t3, 1);
                                // }
                                // t4 = this.getNewDataDays(t4, parseInt(this.freeRentArr[i].noFeeTimeLong)-1);
                                t4 = this.getNewDataYears2(t2, 1);
                                total2 = t3 + "至" + t4;
                                this.freeRentArr[i].timeFree = total1;
                                this.freeRentArr[i].timeTotal = total2;
                                this.freeRentArr[i].noFeeBeginTime = t1;
                                this.freeRentArr[i].noFeeEndTime = t2;
                            }
                        } else {
                            if (i == index) {
                                //以后每一年都和上一年相关
                                let t = this.freeRentArr[i - 1].timeTotal.split("至")[1];
                                t1 = this.getNewDataDays(t, 1);
                                //t2 = this.getNewDataDays(t1, parseInt(item)-1);
                                t2=util.dateFormat(util.dateAddDay(util.dateAddMonth(new Date(t1),this.freeRentArr[i].noFeeMonthLong),Number(this.freeRentArr[i].noFeeTimeLong)-1))
                                total1 = t1 + "至" + t2;
                                t3 = t1;
                                // if(this.textIsDays(t3)){
                                //     t4 = this.getNewDataYears1(t3, 1);
                                // } else {
                                //     t4 = this.getNewDataYears2(t3, 1);
                                // }
                                // t4 = this.getNewDataDays(t4, parseInt(item)-1);
                                //t4 = this.getNewDataYears2(t2, 1);
                                t4=util.dateFormat(util.dateAddDay(util.dateAddMonth(new Date(t1),Number(this.freeRentArr[i].noFeeMonthLong)+12),-1))
                                total2 = t3 + "至" + t4;
                                this.freeRentArr[i].timeFree = total1;
                                this.freeRentArr[i].timeTotal = total2;
                                this.freeRentArr[i].noFeeBeginTime = t1;
                                this.freeRentArr[i].noFeeEndTime = t2;
                            } else {
                                //以后每一年都和上一年相关
                                let t = this.freeRentArr[i - 1].timeTotal.split("至")[1];
                                t1 = this.getNewDataDays(t, 1);
                                //t2 = this.getNewDataDays(t1, parseInt(this.freeRentArr[i].noFeeTimeLong)-1);
                                t2=util.dateFormat(util.dateAddDay(util.dateAddMonth(new Date(t1),this.freeRentArr[i].noFeeMonthLong),Number(this.freeRentArr[i].noFeeTimeLong)-1))
                                total1 = t1 + "至" + t2;
                                t3 = t1;
                                // if(this.textIsDays(t3)){
                                //     t4 = this.getNewDataYears1(t3, 1);
                                // } else {
                                //     t4 = this.getNewDataYears2(t3, 1);
                                // }
                                // t4 = this.getNewDataDays(t4, parseInt(this.freeRentArr[i].noFeeTimeLong)-1);
                                t4 = this.getNewDataYears2(t2, 1);
                                total2 = t3 + "至" + t4;
                                this.freeRentArr[i].timeFree = total1;
                                this.freeRentArr[i].timeTotal = total2;
                                this.freeRentArr[i].noFeeBeginTime = t1;
                                this.freeRentArr[i].noFeeEndTime = t2;
                            }
                        }
                    }*/
                }
                if (this.contractInfo.freeTimeType.value == config.FREE_PAY_INNTER){
                this.contractInfo.noFeeEndTime = this.freeRentArr[
                this.freeRentArr.length - 1
                    ].timeTotal.split("至")[1];
                }
            },
            //计算总免周期
            getTotalFreeTime() {
                console.log(this.freeRentArr.length,"this.freeRentArr.length===")
                for (let i = 0; i < this.freeRentArr.length; i++) {
                    if(this.freeRentArr[i].noFeeMonthLong){
                       this.freeMonthTotal += parseInt(this.freeRentArr[i].noFeeMonthLong);
                    }else{
                        this.freeMonthTotal +=0
                    }
                    if (this.freeRentArr[i].noFeeTimeLong) {
                        this.freeTimeTotal += parseInt(this.freeRentArr[i].noFeeTimeLong);
                    } else {
                        this.freeTimeTotal += 0;
                    }
                }
                console.log(this.freeMonthTotal,"this.freeMonthTotal===")
            },
            //交房日期确认时间
            GiveHouseTimeConfirm(date){
                this.contractInfo.giveHouseTime = this.formatDays(date)
                this.isGiveHouseTimeSelect = false
            },
            //格式化日期方法
            formatDays(time){
                let year = time.getFullYear();
                let months = time.getMonth() + 1;
                if (months < 10) months = "0" + months;
                let days = time.getDate();
                if (days < 10) days = "0" + days;
                return year+'-'+months+'-'+days
            },
            //关闭时间弹窗
            cancel(){
                //关闭交房日期时间弹窗
                this.isGiveHouseTimeSelect = false;
                //装修结束日期时间弹窗
                this.isEndDecorateTimeSelect = false;
                this.isCheckInDateShow = false;
            },
            getNewDataDays(dateTemp, days) {
                var arr = dateTemp.split("-");
                var nDate = new Date(arr[1] + "/" + arr[2] + "/" + arr[0]); //转换为MM-DD-YYYY格式
                var millSeconds = Math.abs(nDate) + days * 24 * 60 * 60 * 1000;
                var rDate = new Date(millSeconds);
                var year = rDate.getFullYear();
                var month = rDate.getMonth() + 1;
                if (month < 10) month = "0" + month;
                var date = rDate.getDate();
                if (date < 10) date = "0" + date;
                return year + "-" + month + "-" + date;
            },
            getNewDataYears1(dateTemp, years) {
                var arr = dateTemp.split("-");
                var nDate = new Date(arr[1] + "/" + arr[2] + "/" + arr[0]); //转换为MM-DD-YYYY格式
                nDate.setFullYear(nDate.getFullYear() + years);
                var millSeconds = Math.abs(nDate) - 24 * 60 * 60 * 1000;
                var rDate = new Date(millSeconds);
                var year = rDate.getFullYear();
                var month = rDate.getMonth() + 1;
                if (month < 10) month = "0" + month;
                var date = rDate.getDate();
                if (date < 10) date = "0" + date;
                return year + "-" + month + "-" + date;
            },
            getNewDataYears2(dateTemp, years) {
                var arr = dateTemp.split("-");
                var nDate = new Date(arr[1] + "/" + arr[2] + "/" + arr[0]); //转换为MM-DD-YYYY格式
                nDate.setFullYear(nDate.getFullYear() + years);
                var year = nDate.getFullYear();
                var month = nDate.getMonth() + 1;
                if (month < 10) month = "0" + month;
                var date = nDate.getDate();
                if (date < 10) date = "0" + date;
                return year + "-" + month + "-" + date;
            },
            //判断传的日期是不是闰年且开始月份为2月份
            textIsDays(dateTemp){
                var arr = dateTemp.split("-");
                var nDate = new Date(arr[1] + "/" + arr[2] + "/" + arr[0]); //转换为MM-DD-YYYY格式
                var year = nDate.getFullYear();
                var month = nDate.getMonth() + 1;
                if(year%100!=0&&year%4==0||year%400==0){
                    //在判断是不是二月份
                    if(month==2){
                        return true
                    }
                }
                return false
            },
            //页面修改的地方 start
            //点击下拉框区域外 下拉框隐藏
            showOrHiddenOfOwnerType() {
                if(this.flagType == '1'){
                    return
                }
                this.isOwnerTypeSelect = true
            },
            //点击下拉框选项 进行数据绑定
            selectOwnerType(item) {
                //如果点击的就是自己直接返回
                if(this.contractInfo.signedType.value==item.value){
                    this.isOwnerTypeSelect = false;
                    return
                }
                this.contractInfo.signedType = item;
                this.isOwnerTypeSelect = false;
                this.signRoleTypeChange(item.value)
            },
            //显示线上签约
            chooseOnline() {
                this.isOnlineSign = true;
                this.isUplineSign = false;
            },
            //显示线下签约
            chooseUpline() {
                this.isOnlineSign = false;
                this.isUplineSign = true;
            },
            //点击银行下拉框区域外 下拉框隐藏
            showOrHiddenOfBankType() {
                this.isBankTypeSelect = !this.isBankTypeSelect;
            },
            showOrHiddenOfProvince() {
                this.isProvinceSelect = !this.isProvinceSelect;
            },
            showOrHiddenOfCity() {
                this.isCitySelect = !this.isCitySelect;
            },
            //储蓄类型选择
            chooseBankType(item) {
                this.bankTypeInfo = item;
                this.bankTypeChoose = false;
            },
            //点击银行下拉框选项 进行数据绑定
            selectBankType(item) {
                this.contractInfo.bankType = item;
                this.isBankTypeSelect = false;
                this.isBankTypeSelectAready = true;
            },
            selectProvince(item) {
                this.contractInfo.province = item;
                this.isProvinceSelect = false;
                this.changeProvince()
                this.contractInfo.city={id:'',name:''}
                //this.isProvinceSelectAready = true;
            },
            selectCity(item) {
                this.contractInfo.city = item;
                this.isCitySelect = false;
                //this.isCitySelectAready = true;
            },
            //显示隐藏代理人
            showOrHiddenProxyInfo() {
                this.isChooseProxy = !this.isChooseProxy;
            },
            //显示隐藏共有权人
            showOrHiddenCoOwnerInfo(id) {
                if(id == 1){
                    this.isChooseCoOwner = true;
                }else{
                    this.isChooseCoOwner = false;

                }
            },
            //显示与隐藏交房时间
            clickGiveHouseTimeSelect() {
                this.isGiveHouseTimeSelect = !this.isGiveHouseTimeSelect;
            },
            //显示与隐藏装修开始时间
            clickStartDecorateTimeShow() {
                this.isStartDecorateTimeSelect = !this.isStartDecorateTimeSelect;
                var panel = document.getElementById("startDecorate");
                if (panel) {
                    document.addEventListener("click", e => {
                        if (!panel.contains(e.target)) {
                            this.isStartDecorateTimeSelect = false;
                        }
                    });
                }
            },
            //显示与隐藏装修结束时间
            clickEndDecorateTimeShow() {
                this.isEndDecorateTimeSelect = !this.isEndDecorateTimeSelect;
                var panel = document.getElementById("endDecorate");
                if (panel) {
                    document.addEventListener("click", e => {
                        if (!panel.contains(e.target)) {
                            this.isEndDecorateTimeSelect = false;
                        }
                    });
                }
            },




            //选择标签
            choosePaperContent(item) {
                !item.isChoose?this.$set(item,'isChoose', true):this.$set(item,'isChoose', false)
            },
            //显示与隐藏合作占比
            showOrHiddenOfCooperationType() {
                this.isCooperationTypeSelect = !this.isCooperationTypeSelect;
            },
            //页面修改的地方 end

            //返回上一层
            leftReturn() {
                this.$router.go(-1);
            },

            watchScroll() {
                var scrollTop =
                    window.pageYOffset ||
                    document.documentElement.scrollTop ||
                    document.body.scrollTop;
                if (scrollTop > 80) {
                    this.isFixed = true;
                } else {
                    this.isFixed = false;
                }
            },

            isTenantInfo() {
                this.whichInfo = "0";
            },

            isContractInfo() {
                this.whichInfo = "1";
            },

            afterReadSingle(file,base64Str,type){
                let that = this
                let initData = {
                    base64: base64Str
                }
                that.loadingFlag = true;
                base64Upload(initData).then(function (response) {
                    that.loadingFlag = false;
                    responseUtil.dealResponse(that, response, () => {
                        //1 业主身份证正面  2 反面   3 手持   4  银行卡正面 5 背面 6 代理人身份证正面 7反面 8 业主委托书 9 业主手持委托书 10 共有权人身份证正面 11 身份证反面
                        if(type == '1'){
                            that.ownerInfo.yzFrontOfCard = response.data.data.path
                        }else if(type == '2'){
                            that.ownerInfo.yzReverseOfCard = response.data.data.path
                        }else if(type == '3'){
                            that.ownerInfo.yzHoldCard = response.data.data.path
                        }else if(type == '4'){
                            that.ownerInfo.bankCardFront = response.data.data.path
                        }else if(type == '5'){
                            that.ownerInfo.bankCardReverse = response.data.data.path
                        }else if(type == '6'){
                            that.proxyInfo.wtrFrontOfCard = response.data.data.path
                        }else if(type == '7'){
                            that.proxyInfo.wtrReverseOfCard = response.data.data.path
                        }else if(type == '8'){
                            that.proxyInfo.entrustment = response.data.data.path
                        }else if(type == '9'){
                            that.proxyInfo.holdEntrustment = response.data.data.path
                        }else if(type == '10'){
                            that.coOwner.commonOwnerFrontOfCard = response.data.data.path
                        }else if(type == '11'){
                            that.coOwner.commonOwnerReverseOfCard = response.data.data.path
                        }

                    })
                })
            },

            //上传证件
            afterReadCertificateFront(file) {
                let that = this
                //压缩图片
                dealImageSingle(file,file.content,that.afterReadSingle,'1')
            },

            afterReadCertificateBack(file) {
                let that = this
                //压缩图片
                dealImageSingle(file,file.content,that.afterReadSingle,'2')
            },

            afterReadCertificateHandHeld(file) {
                let that = this
                //压缩图片
                dealImageSingle(file,file.content,that.afterReadSingle,'3')
            },

            cohabitingPersonCertificateFront(file) {
                let that = this
                let initData = {
                    base64: file.content
                }
                that.loadingFlag = true;
                base64Upload(initData).then(function (response) {
                    that.loadingFlag = false;
                    responseUtil.dealResponse(that, response, () => {
                        that.ownerInfo.yzHoldCard = response.data.data.path
                    })
                })
                this.cohabitingPersonInfo.yzFrontOfCard = file.content
            },

            cohabitingPersonCertificateBack(file) {
                let that = this
                let initData = {
                    base64: file.content
                }
                that.loadingFlag = true;
                base64Upload(initData).then(function (response) {
                    that.loadingFlag = false;
                    responseUtil.dealResponse(that, response, () => {
                        that.ownerInfo.yzHoldCard = response.data.data.path
                    })
                })
                this.cohabitingPersonInfo.yzReverseOfCard = file.content
            },

            cohabitingPersonCertificateHandHeld(file) {
                let that = this
                let initData = {
                    base64: file.content
                }
                that.loadingFlag = true;
                base64Upload(initData).then(function (response) {
                    that.loadingFlag = false;
                    responseUtil.dealResponse(that, response, () => {
                        that.ownerInfo.yzHoldCard = response.data.data.path
                    })
                })
                this.cohabitingPersonInfo.yzHoldCard = file.content
            },
            //上传银行卡
            afterReadBankCardFront(file) {
                let that = this
                //压缩图片
                dealImageSingle(file,file.content,that.afterReadSingle,'4')
            },

            afterReadBankCardBack(file) {
                let that = this
                //压缩图片
                dealImageSingle(file,file.content,that.afterReadSingle,'5')
            },
            //上传代理人证件信息和业主委托
            afterReadProxyCertificateFront(file) {
                let that = this
                //压缩图片
                dealImageSingle(file,file.content,that.afterReadSingle,'6')
            },
            afterReadProxyCertificateBack(file) {
                let that = this
                //压缩图片
                dealImageSingle(file,file.content,that.afterReadSingle,'7')
            },
            afterReadownerEntrust(file) {
                let that = this
                //压缩图片
                dealImageSingle(file,file.content,that.afterReadSingle,'8')
            },
            afterReadownerEntrustHandle(file) {
                let that = this
                //压缩图片
                dealImageSingle(file,file.content,that.afterReadSingle,'9')
            },
            //上传共有权人证件
            afterReadCoOwnerCertificateFront(file) {
                let that = this
                //压缩图片
                dealImageSingle(file,file.content,that.afterReadSingle,'10')
            },
            afterReadCoOwnerCertificateBack(file) {
                let that = this
                //压缩图片
                dealImageSingle(file,file.content,that.afterReadSingle,'11')
            },
            changUpload(name){
                this.$refs[name].chooseFile()
            },
            afterRead(file,base64Str,i,fileLength){
                let that = this
                let initData = {
                    base64: base64Str
                }
                that.loadingFlag = true,
                    base64Upload(initData).then(function (response) {
                        responseUtil.dealResponse(that, response, () => {
                            file.path = response.data.data.path
                            // that.loadingFlag = false
                            if(i == fileLength){
                                that.loadingFlag = false
                            }
                        })
                    })
            },
            //线下合同、产权证明图片多选上传
            afterReadTest(file){
                // this.loadingFlag = true;
                // alert(file.content.length)
                let fileLength = parseInt(file.length) - parseInt(1)
                if(file.length > 1){
                    for(let i = 0; i<file.length;i++){
                        let fileDetail = file[i];
                        dealImage(fileDetail,fileDetail.content,400,this.afterRead,i,fileLength);
                    }
                }else{
                    // this.afterRead(file);
                    dealImage(file,file.content,400,this.afterRead);
                }
            },
/*            afterReadEnclosurePropertyPicList(file) {
                let that = this
                let initData = {
                    base64: file.content
                }
                that.loadingFlag = true;
                base64Upload(initData).then(function (response) {
                    that.loadingFlag = false;
                    responseUtil.dealResponse(that, response, () => {
                        let obj = response.data.data
                        obj.path = obj.path
                        that.contractInfo.enclosurePropertyPicList.push(obj)
                    })
                })
            },*/
            isliveTogether() {
                this.ownerInfo.liveTogether = "1";
            },

            noliveTogether() {
                this.ownerInfo.liveTogether = "0";
            },
            //点击下拉框区域外 下拉框隐藏
            clickCardShow() {
                if (this.ownerInfo.certificateState) {
                    return
                }
                this.isCardShow = !this.isCardShow;
            },
            clickCoOwnerCardShow() {
                this.isCoOwnerCardShow = !this.isCoOwnerCardShow;
            },
            clickProxyCardShow() {
                if (this.proxyInfo.certificateState) {
                    return
                }
                this.isProxyCardShow = !this.isCardShow;
            },
            //点击下拉框选项 进行数据绑定
            selectCardOption(item) {
                this.ownerInfo.certificateType = item;
                this.isCardShow = false;
                this.isCardSelected = true;
            },
            //点击下拉框选项 进行数据绑定
            selectProxyCard(item) {
                this.proxyInfo.certificateType = item;
                this.isProxyCardShow = false;
                this.isProxyCardSelected = true;
            },
            selectCoOwnerCard(item) {
                this.coOwner.certificateType = item;
                this.isCoOwnerCardShow = false;
                this.isCoOwnerCardSelected = true;
            },

            //点击下拉框区域外 下拉框隐藏
            clickCohabitingPersonCardShow() {
                this.isCohabitingPersonCardShow = !this.isCohabitingPersonCardShow;
            },
            //点击下拉框选项 进行数据绑定
            selectCohabitingPersonCardOption(item) {
                this.cohabitingPersonInfo.certificateType = item;
                this.isCohabitingPersonCardShow = false;
                this.isCohabitingPersonCardSelected = true;
            },
            //点击免租期类型下拉框绑定属性
            selectFreeTimeType(item) {
                if (this.contractInfo.leaseTerm) {
                    this.contractInfo.leaseTerm = this.contractInfo.leaseTerm //+ 0;
                    //setTimeout(this.setTimes, 1);
                }
                this.contractInfo.freeTimeType = item;
                this.isFreeTimeTypeSelect = false;
                this.isFreeTimeTypeSelectAready = true;
            },
            setTimes() {
                this.contractInfo.leaseTerm =
                    parseInt(this.contractInfo.leaseTerm) / 10 + "";
            },
            //点击权属类型下拉框绑定属性
            selectOwnershipType(item) {
                this.contractInfo.ownershipType = item;
                this.isOwnershipTypeSelect = false;
                this.isOwnershipTypeSelectAready = true;
            },
            //点击朝向下拉框绑定属性
            selectToWhereType(item) {
                this.contractInfo.toWhereType = item;
                this.isToWhereTypeSelect = false;
                this.isToWhereTypeSelectAready = true;
            },
            //点击供暖下拉框绑定属性
            selectHeatingType(item) {
                this.contractInfo.heatingType = item;
                this.isHeatingTypeSelect = false;
                this.isHeatingTypeSelectAready = true;
            },
            //点击抵押下拉框绑定属性
            selectMortgageType(item) {
                this.contractInfo.mortgageType = item;
                this.isMortgageTypeSelect = false;
                this.isMortgageTypeSelectAready = true;
            },
            //点击合作下拉框绑定属性
            selectCooperationType(item) {
                this.contractInfo.cooperationType = item;
                this.isCooperationTypeSelect = false;
                this.isCooperationTypeSelectAready = true;
            },
            clickPaymentMethodShow() {
                this.isPaymentMethodShow = !this.isPaymentMethodShow;
            },
            selectPaymentMethodOption(item) {
                this.contractInfo.paymentMethod = item;
                this.isPaymentMethodShow = false;
                this.isPaymentMethodSelected = true;
                if(this.contractInfo.freeTimeType.value == config.FREE_PAY_OUT){
                  this.getCostLimit()
                }
            },
            clickStartPeriodShow() {
                this.isStartPeriodShow = !this.isStartPeriodShow;
                var panel = document.getElementById("startPeriodPanel");
                if (panel) {
                    document.addEventListener("click", e => {
                        if (!panel.contains(e.target)) {
                            this.isStartPeriodShow = false;
                        }
                    });
                }
            },

            //交房时间选择确定
            startGiveHouseConfirm(date) {
                this.isGiveHouseTimeSelect=false
                this.contractInfo.giveHouseTime=this.formatDate(date)
                this.contractInfo.startDecorateTime = this.contractInfo.giveHouseTime
            },
            formatDate(date){
                // let month = date.getMonth() >=10 ? date.getMonth()+1 :'0' +(date.getMonth()+1)
                // let day = date.getDate() >= 10 ? date.getDate() : '0' + date.getDate()
                // return   `${date.getFullYear()}-${month}-${day}`;
                var year = date.getFullYear();
                var month = date.getMonth() + 1;
                if (month < 10) month = "0" + month;
                var days = date.getDate();
                if (days < 10) days = "0" + days;
                return year + "-" + month + "-" + days;
            },
            //装修开始
            startDecorateTimeConfirm(date) {
                // var date = new Date(value);
                this.contractInfo.startDecorateTime = this.formatDate(date)
            },
            //装修开始取消
            startDecorateTimeCancel() {
                this.isStartDecorateTimeSelect = false;
            },
            //装修结束
            endDecorateTimeConfirm(date) {
                let startTime = new Date(this.commonDateFormate(this.contractInfo.startDecorateTime)).getTime()
                let endTime = this.formatDate(date)
                endTime = new Date(this.commonDateFormate(endTime)).getTime()
                if(endTime<startTime){
                    responseUtil.alertMsg(this,'装修结束时间不能早于装修开始时间')
                    return
                }
                this.contractInfo.endDecorateTime = this.formatDate(date)
                this.contractInfo.noFeeBeginTime = this.getNewDataDays(
                    this.contractInfo.endDecorateTime,
                    1
                );
                this.isEndDecorateTimeSelect = false;
                if (this.contractInfo.leaseTerm) {
                    this.contractInfo.leaseTerm = this.contractInfo.leaseTerm //+ 0;
                    //setTimeout(this.setTimes, 1);
                }
                    if(this.contractInfo.freeTimeType.value == config.FREE_PAY_OUT){
                        this.getCostLimit()
                    }
            },
            //装修结束开始取消
            // endDecorateTimeCancel() {
            //     this.isEndDecorateTimeSelect = false;
            // },
            //日期选择组件 点击确认键后执行方法
            startPeriodConfirm(value) {
                var date = new Date(value);
                var year = date.getFullYear();
                var month = date.getMonth() + 1;
                if (month < 10) month = "0" + month;
                var days = date.getDate();
                if (days < 10) days = "0" + days;
                this.contractInfo.noFeeBeginTime = year + "-" + month + "-" + days;
                this.isStartPeriodShow = false;
            },
            //日期选择组件 点击取消键后执行方法
            startPeriodCancel() {
                this.isStartPeriodShow = false;
            },
            clickEndPeriodShow() {
                this.isEndPeriodShow = !this.isEndPeriodShow;
                var panel = document.getElementById("endPeriodPanel");
                if (panel) {
                    document.addEventListener("click", e => {
                        if (!panel.contains(e.target)) {
                            this.isEndPeriodShow = false;
                        }
                    });
                }
            },
            //日期选择组件 点击确认键后执行方法
            endPeriodConfirm(value) {
                var date = new Date(value);
                this.contractInfo.noFeeEndTime =
                    date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
                this.isEndPeriodShow = false;
            },
            //日期选择组件 点击取消键后执行方法
            endPeriodCancel() {
                this.isEndPeriodShow = false;
            },
            //日期选择组件 点击确认键后执行方法
            checkInDateConfirm(value) {
                var date = new Date(value);
                this.contractInfo.checkInDate =
                    date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
                this.isCheckInDateShow = false;
            },
            //日期选择组件 点击取消键后执行方法
            checkInDateCancel() {
                this.isCheckInDateShow = false;
            },
            chooseLabel(index) {
                this.labelList[index].isChoose = !this.labelList[index].isChoose;
                if (this.labelList[index].isChoose) {
                    this.contractInfo.labelList.push(this.labelList[index]);
                }
                if (!this.labelList[index].isChoose) {
                    for (var i = 0; i < this.contractInfo.labelList.length; i++) {
                        if (
                            this.labelList[index].name == this.contractInfo.labelList[i].name
                        ) {
                            this.contractInfo.labelList.splice(i, 1);
                            break;
                        }
                    }
                }
            },
            chooseDiscounts(item) {
                this.contractInfo.discounts = item;
            },
            //点击下拉框区域外 下拉框隐藏
            clickSignatoryShow() {
                // this.isSignatoryShow = !this.isSignatoryShow;
                // var panel = document.getElementById("signatoryPanel");
                // if (panel) {
                //     document.addEventListener("click", e => {
                //         if (!panel.contains(e.target)) {
                //             this.isSignatoryShow = false;
                //         }
                //     });
                // }
                this.$router.push({
                    name:'referrer',
                    query:{
                        type: 1,
                    }
                })
            },
            //点击下拉框选项 进行数据绑定
            selectSignatoryOption(item) {
                this.contractInfo.signatory = item;
                this.isSignatoryShow = false;
                this.isSignatorySelected = true;
            },
            //点击下拉框区域外 下拉框隐藏
            clickPartnerShow() {
                // this.isPartnerShow = !this.isPartnerShow;
                // let panel = document.getElementById("partnerPanel");
                // if (panel) {
                //     document.addEventListener("click", e => {
                //         if (!panel.contains(e.target)) {
                //             this.isPartnerShow = false;
                //         }
                //     });
                // }
                this.$router.push({
                    name:'referrer',
                    query:{
                        type: 2,
                    }
                })
            },
            //点击下拉框选项 进行数据绑定
            selectPartnerOption(item) {
                this.contractInfo.partner = item;
                this.isPartnerShow = false;
                this.isPartnerSelected = true;
            },
            //点击下拉框区域外 下拉框隐藏
            clickPartnershipProportionShow() {
                this.isPartnershipProportionShow = !this.isPartnershipProportionShow;
                this.referrerText = '';
            },
            //点击下拉框选项 进行数据绑定
            selectPartnershipProportionOption(item) {
                this.contractInfo.partnershipProportion = item;
                this.isPartnershipProportionShow = false;
                this.isPartnershipProportionSelected = true;
                this.referrerText = '';
            },

            checkCanSave() {
                let contractInfo = this.contractInfo;
                let ownerInfo = this.ownerInfo;
                let proxyInfo = this.proxyInfo
                let coOwner = this.coOwner
                if (
                    contractInfo.signedType  &&
                    ownerInfo.phone > 0 &&
                    ownerInfo.name&&
                    ownerInfo.certificateCode&&
                    ownerInfo.emergencyContactName&&
                    ownerInfo.emergencyContactPhone &&
                    ownerInfo.receiver&&
                    contractInfo.bankType.value  &&
                    ownerInfo.bankChanel&&
                    ownerInfo.bankBob&&
                    ownerInfo.bankCard&&
                    (!this.isChooseProxy || (this.isChooseProxy && proxyInfo.name && proxyInfo.phone && proxyInfo.certificateCode)) &&
                    (!this.isChooseCoOwner || (this.isChooseCoOwner && coOwner.name && coOwner.phone && coOwner.IdCard)) &&
                    contractInfo.giveHouseTime &&
                    contractInfo.startDecorateTime &&
                    contractInfo.endDecorateTime&&
                    contractInfo.noFeeBeginTime&&
                    contractInfo.noFeeEndTime&&
                    contractInfo.rent&&
                    contractInfo.leaseTerm&&
                    contractInfo.ownershipType.value &&
                    ownerInfo.rightNumber&&
                    ownerInfo.buildArea &&
                    contractInfo.toWhereType.value &&
                    contractInfo.signatory.id &&
                    (!contractInfo.partner || (contractInfo.partner && contractInfo.cooperationType))
                    &&(!contractInfo.partnershipProportion.label?true:!contractInfo.channelMoney?false:contractInfo.channelMoney>0)
                    &&this.propertyRightAddress
                ) {
                    return true;
                }
                return false;
            },
          channelMoneyInput(e){
            console.log(e)
          },
            //保存时非空和格式判断
            canReallySave(){
                let that = this
                let contractInfo = that.contractInfo;
                let ownerInfo = that.ownerInfo;
                let proxyInfo = that.proxyInfo
                let coOwner = that.coOwner
                //签约角色
                if(!contractInfo.signedType){
                    responseUtil.alertMsg(that,'请选择签约人类型')
                    return false
                }
                //业主手机号
                if(!ownerInfo.phone){
                    responseUtil.alertMsg(that,'请输入业主手机号')
                    return false
                } else {
                    let re = config.MOBILE_REG
                    let str = ownerInfo.phone
                    if (!re.test(str)) {
                        responseUtil.alertMsg(that,'业主手机号格式不正确')
                        return false
                    }
                }
                //业主姓名
                if(!ownerInfo.name){
                    responseUtil.alertMsg(that,'请输入业主姓名')
                    return false
                }
                //业主省份证
                if(!ownerInfo.certificateCode){
                    responseUtil.alertMsg(that,'请输入业主身份信息')
                    return false
                } else if(ownerInfo.certificateType.value == 0 || ownerInfo.certificateType.value== undefined) {
                    let re = config.CERTIFICATE_REG
                    let str = ownerInfo.certificateCode
                    if (str!= '' && !re.test(str)) {
                        responseUtil.alertMsg(that,'业主身份证格式不正确')
                        return false
                    }
                }
                //紧急联系人
                if(!ownerInfo.emergencyContactName){
                    responseUtil.alertMsg(that,'请输入紧急联系人姓名')
                    return false
                }
                //紧急联系人电话
                if(!ownerInfo.emergencyContactPhone){
                    responseUtil.alertMsg(that,'请输入紧急联系人手机号')
                    return false
                } else {
                    let re = config.MOBILE_REG
                    let str = ownerInfo.emergencyContactPhone
                    if (!re.test(str)) {
                        responseUtil.alertMsg(that,'紧急联系人手机号格式不正确')
                        return false
                    }
                }
                //邮箱信息
                if(ownerInfo.email){
                    let re = config.EMAIL_REG
                    let str = ownerInfo.email;
                    if (!re.test(str)) {
                        responseUtil.alertMsg(that,'邮箱格式不正确')
                        return false
                    }
                }
                //收款人信息
                if(!ownerInfo.receiver){
                    responseUtil.alertMsg(that,'请输入收款人姓名')
                    return false
                }
                //收款银行卡号
                if(!ownerInfo.bankCard){
                    responseUtil.alertMsg(that,'请输入收款账号')
                    return false
                } else if(ownerInfo.bankCard&&(that.bankTypeInfo.value==374 || that.bankTypeInfo.value == undefined)){
                    let str = ownerInfo.bankCard
                    if (!util.luhnCheck(str)) {
                        responseUtil.alertMsg(that,'收款银行卡卡号不正确')
                        return false
                    }
                }
                //所属银行
                if(!contractInfo.bankType.label){
                    responseUtil.alertMsg(that,'请选择银行类型')
                    return false
                }
                //支行
                if(!ownerInfo.bankChanel){
                    responseUtil.alertMsg(that,'请输入所属支行')
                    return false
                }
                //行号
                if(!ownerInfo.bankBob){
                    responseUtil.alertMsg(that,'请输入行号')
                    return false
                }
                if(!contractInfo.province.id){
                    responseUtil.alertMsg(that,'请选择省份')
                    return false
                }
                if(!contractInfo.city.id){
                    responseUtil.alertMsg(that,'请选择城市')
                    return false
                }
                //代理人信息
                if(that.isChooseProxy){
                    //姓名
                    if(!proxyInfo.name){
                        responseUtil.alertMsg(that,'请输入代理人姓名')
                        return false
                    }
                    //手机号
                    if(!proxyInfo.phone){
                        responseUtil.alertMsg(that,'请输入代理人手机号')
                        return false
                    } else {
                        let re = config.MOBILE_REG
                        let str = proxyInfo.phone
                        if (!re.test(str)) {
                            responseUtil.alertMsg(that,'代理人手机号格式不正确')
                            return false
                        }
                        if(str==ownerInfo.phone){
                          responseUtil.alertMsg(that,'代理人手机号与业主手机号不能相同')
                          return false
                        }
                    }
                    //身份证
                    if(!proxyInfo.certificateCode){
                        responseUtil.alertMsg(that,'请输入代理人身份信息')
                        return false
                    } else if(proxyInfo.certificateType.value == 0 || proxyInfo.certificateType.value== undefined){
                        let re = config.CERTIFICATE_REG
                        let str = proxyInfo.certificateCode
                        if (str!= '' && !re.test(str)) {
                            responseUtil.alertMsg(that,'代理人身份证格式不正确')
                            return false
                        }
                    }
                }
                //共有权人信息
                if(that.isChooseCoOwner){
                    //姓名
                    if(!coOwner.name){
                        responseUtil.alertMsg(that,'请输入共有权人姓名')
                        return false
                    }
                    //手机号
                    if(!coOwner.phone){
                        responseUtil.alertMsg(that,'请输入共有权人手机号')
                        return false
                    } else {
                        let re = config.MOBILE_REG
                        let str = coOwner.phone
                        if (!re.test(str)) {
                            responseUtil.alertMsg(that,'共有权人手机号格式不正确')
                            return false
                        }
                    }
                    //身份证
                    if(!coOwner.IdCard){
                        responseUtil.alertMsg(that,'请输入共有权人身份信息')
                        return false
                    } else if(coOwner.certificateType.value == 0 || coOwner.certificateType.value== undefined){
                        let re = config.CERTIFICATE_REG
                        let str = coOwner.IdCard
                        if (!re.test(str)) {
                            responseUtil.alertMsg(that,'共有权人身份证格式不正确')
                            return false
                        }
                    }
                }
                //交房日期
                if(!contractInfo.giveHouseTime){
                    responseUtil.alertMsg(that,'请选择交房时间')
                    return false
                }
                //装修开始时间
                if(!contractInfo.startDecorateTime){
                    responseUtil.alertMsg(that,'请选择装修开始时间')
                    return false
                }
                //装修结束时间
                if(!contractInfo.endDecorateTime){
                    responseUtil.alertMsg(that,'请选择装修结束时间')
                    return false
                }
                //免租期类型
                if(!contractInfo.freeTimeType){
                    responseUtil.alertMsg(that,'请选择免租期类型')
                    return false
                }
                //合同年限
                if(!contractInfo.leaseTerm){
                    responseUtil.alertMsg(that,'请输入合同年限')
                    return false
                }
                //合同开始时间
                if(!contractInfo.noFeeBeginTime){
                    responseUtil.alertMsg(that,'请选择合同开始时间')
                    return false
                }
                //合同结束时间
                if(!contractInfo.noFeeEndTime){
                    responseUtil.alertMsg(that,'请选择合同结束时间')
                    return false
                }
                //租金
                if(!contractInfo.rent){
                    responseUtil.alertMsg(that,'请输入租金')
                    return false
                }
                //付款方式
                if(!contractInfo.paymentMethod){
                    responseUtil.alertMsg(that,'请选择付款方式')
                    return false
                }
                //权属类型
                if(!contractInfo.ownershipType.value){
                    responseUtil.alertMsg(that,'请选择权属类型')
                    return false
                }
                //产权证
                if(!ownerInfo.rightNumber){
                    responseUtil.alertMsg(that,'请输入产权证号')
                    return false
                }
                //面积
                if(!ownerInfo.buildArea){
                    responseUtil.alertMsg(that,'请输入面积')
                    return false
                }
                //朝向
                if(!contractInfo.toWhereType.value){
                    responseUtil.alertMsg(that,'请选择朝向')
                    return false
                }
                //签约人
                if(!contractInfo.signatory){
                    responseUtil.alertMsg(that,'请选择签约人')
                    return false
                }
                //合作占比
                if(contractInfo.partner){
                    if(!contractInfo.cooperationType){
                        responseUtil.alertMsg(that,'请选择合作占比')
                        return false
                    }
                }
                return true
            },
            setSelectObj(arr, value, keyName) {
                let obj = {}
                keyName = keyName || 'value'
                arr.forEach((item) => {
                    if (item[keyName] == value) {
                        obj = item
                    }
                })
                return obj
            },
            //根据标题在数组中筛选
            getObjByTitle(arr,title){
                for(let i in arr){
                    if(arr[i].label==title){
                        return arr[i]
                    }
                }
                return ''
            },
            requestParams() {
                let that = this;
                let requestParams = {
                    //合同历史id
                    contracthistoryId:that.contracthistoryId || "",
                    //租约变更信息
                    terminateInfoParam:that.terminateInfoParam,
                    id: that.contractInfo.id || "",
                    // 楼盘字典id
                    estateRoom_id: that.ownerInfo.estateRoom_id,
                    // 潜房id
                    potentialResource_id: that.ownerInfo.potentialResource_id,
                    // 录入人id
                    AddStaffId: getStaffId(),
                    // 房屋详细地址
                    houseAddress: that.houseAddress,
                    propertyRightAddress: that.propertyRightAddress,
                    // 签约人角色
                    signRoleType: that.contractInfo.signedType.value + "",
                    // 合同编号
                    contractCode: that.contractInfo.number + "",
                    //合同编号(续租时使用，新的)
                    contractCodeNew:  that.contractInfo.newNumber + "",
                    //付款方式id
                    payTypeId: that.contractInfo.paymentMethod.value,
                    // 银行卡号
                    bankCode: that.ownerInfo.bankCard,
                    // 签约人
                    signStaffId: that.contractInfo.signatory.id,
                    // 合作人 partnerStaffId
                    partnerStaffId: that.contractInfo.partner.id,
                    // 合作占比
                    partnerRatioId: that.contractInfo.cooperationType.id,
                    // 签约人部门id
                    signDepartment_id: that.contractInfo.signDepartment.id,
                    // 合作人部门id partnerDepartment_id
                    partnerDepartment_id: that.contractInfo.partner.department_id,
                    // 账单
                    bills: that.createBill(),
                    // 免租期列表
                    noFeeList: that.freeRentArr,
                    // 签约类型 0 潜房签约,1 业主合同修改,2 业主合同续租
                    flagType: that.flagType,
                    // 推荐人id
                    recommendUser_id: that.contractInfo.partnershipProportion.id || 0,
                    //业主合同类型(0 重资产 1 轻资产)
                    ownerContractType: "0",
                    //业主合同子类型(0 全程管理 1 委托出租 2 委托管理 3 委托保租)
                    ownerContractSubType: "0",
                    //业主手机号
                    ownerMobile: that.ownerInfo.phone,
                    //业主姓名
                    ownerName: that.ownerInfo.name,
                    //业主性别
                    ownerGender: that.ownerInfo.sex + '',
                    //业主证件类型
                    ownerCertificateType: that.ownerInfo.certificateType.value || "0",
                    //业主证件号码
                    ownerCertificateCode: that.ownerInfo.certificateCode,
                    //紧急联系人
                    emergencyContactPerson: that.ownerInfo.emergencyContactName,
                    //紧急联系电话
                    emergencyContactPhone: that.ownerInfo.emergencyContactPhone,
                    emergencyContactEmail: that.ownerInfo.email,
                    emergencyContactAddress: that.ownerInfo.emergencyAddress,
                    //供暖情况
                    heatingMode: that.contractInfo.heatingType.value,
                    //抵押情况
                    mortgageSituation: that.contractInfo.mortgageType.value,
                    //押金
                    depositMoney: that.contractInfo.deposit,
                    //是否有代理人
                    hasAgent: that.isChooseProxy+'',
                    //是否有共有权人
                    hasCoOwner: that.isChooseCoOwner,
                    //收款人姓名
                    payeeName: that.ownerInfo.receiver,
                    //所属银行
                    bank: that.contractInfo.bankType.id,
                    //储蓄类型
                    savingsCertificateType:that.bankTypeInfo.value || 374,
                    //所属支行
                    subBank: that.ownerInfo.bankChanel,
                    //所属营业部
                    salesDepartmentNo: that.ownerInfo.bankBob,
                    //免租期类型
                    noFeeType: that.contractInfo.freeTimeType.value,
                    //产权类型id
                    propertyRightType_id: that.contractInfo.ownershipType.id,
                    //产权证号
                    propertyRightCode: that.ownerInfo.rightNumber,
                    //房源面积 :
                    builtUpArea: that.ownerInfo.buildArea,
                    //合同年限
                    contractYears: that.contractInfo.leaseTerm,
                    //朝向id
                    orientation_id: that.contractInfo.toWhereType.id,
                    //租金
                    rentMoney: that.contractInfo.rent,
                    //合同开始时间
                    startDate: that.contractInfo.noFeeBeginTime,
                    //合同结束时间
                    endDate: that.contractInfo.noFeeEndTime,
                    //装修开始日期
                    renovationBeginTime: that.contractInfo.startDecorateTime,
                    //装修结束日期
                    renovationEndTime: that.contractInfo.endDecorateTime,
                    //交房时间
                    giveHouseTime: that.contractInfo.giveHouseTime,
                    //补充说明
                    footnote: that.contractInfo.explanationDescribe,
                    //代理人姓名
                    agentName: that.proxyInfo.name,
                    //代理人手机号.
                    agentMobile: that.proxyInfo.phone,
                    //代理人证件类型
                    agentCertificateType: that.proxyInfo.certificateType.value || "0",
                    //代理人证件号
                    agentCertificateCode: that.proxyInfo.certificateCode,
                    wtrFrontOfCard: that.proxyInfo.wtrFrontOfCard==require('../../../assets/images/default_img_4.png')? '' : that.proxyInfo.wtrFrontOfCard, //身份证前面
                    wtrReverseOfCard: that.proxyInfo.wtrReverseOfCard==require('../../../assets/images/default_img_5.png')? '' : that.proxyInfo.wtrReverseOfCard, //身份证后面
                    entrustment: that.proxyInfo.entrustment==require('../../../assets/images/default_img_6.png')? '' : that.proxyInfo.entrustment, //业主委托
                    holdEntrustment: that.proxyInfo.holdEntrustment==require('../../../assets/images/default_img_6.png')? '' : that.proxyInfo.holdEntrustment, //业主手持委托
                    //共有权人姓名
                    coOwnerName: that.coOwner.name,
                    //共有权人手机号
                    coOwnerMobile: that.coOwner.phone,
                    //共有权人证件类型
                    coOwnerCertificateType: that.coOwner.certificateType.value || "0",
                    //共有权人证件号
                    coOwnerCertificateCode: that.coOwner.IdCard,
                    commonOwnerFrontOfCard: that.coOwner.commonOwnerFrontOfCard==require('../../../assets/images/default_img_4.png')? '' : that.coOwner.commonOwnerFrontOfCard, //身份证前面
                    commonOwnerReverseOfCard: that.coOwner.commonOwnerReverseOfCard==require('../../../assets/images/default_img_5.png')? '' : that.coOwner.commonOwnerReverseOfCard, //身份证后面
                    // 1 线上签约 2 线下签约
                    signType: that.isOnlineSign ? "1" : "2",
                    // 证件正面
                    yzFrontOfCard: that.ownerInfo.yzFrontOfCard==require('../../../assets/images/default_img_4.png')? '' : that.ownerInfo.yzFrontOfCard,
                    // 证件背面
                    yzReverseOfCard: that.ownerInfo.yzReverseOfCard==require('../../../assets/images/default_img_5.png')? '' : that.ownerInfo.yzReverseOfCard,
                    // 手持证件图片
                    yzHoldCard: that.ownerInfo.yzHoldCard==require('../../../assets/images/default_img_3.png')? '' : that.ownerInfo.yzHoldCard,
                    // 银行卡正面
                    bankCardFront: that.ownerInfo.bankCardFront==require('../../../assets/images/default_img_2.png')? '' : that.ownerInfo.bankCardFront,
                    // 银行卡背面
                    bankCardReverse: that.ownerInfo.bankCardReverse==require('../../../assets/images/default_img_1.png')? '' : that.ownerInfo.bankCardReverse,
                    // 产权证图片
                    enclosurePropertyPicList: that.contractInfo.enclosurePropertyPicList,
                    // 线下合同图片
                    outLinecontratPicList: that.contractInfo.outLinecontratPicList,
                    //是否装修
                    isRenovation: that.contractInfo.isRenovation,
                    // 标签列表
                    lables: this.getLables(),
                    outLineImgChangeType:that.contractInfo.outLinecontratPicList.length > 0,
                    enclosureImgChangeType:that.contractInfo.enclosurePropertyPicList.length > 0,
                    channelMoney:that.contractInfo.channelMoney,
                    provinceId:that.contractInfo.province.id,
                    cityId:that.contractInfo.city.id,
                    Latestleaseate:that.contractInfo.haveLatestleaseate,
                    maintenanceFundAmount:that.contractInfo.haveLatestleaseate=='0'?0:that.contractInfo.ownerMaintenanceFund,
                    interbankId:that.interbankId

                };
                return requestParams;
            },
            // 修改合同回显数据
            callBackShow(data) {
                if(this.flagType==1){
                  this.contractInfo.ownerMaintenanceFund=data.maintenanceFundAmount
                }else{
                  this.city_id = data.city_id
                  this.houseRoomCount = data.houseRoomCount
                  this.getQueryOwnerMaintenanceFund()
                }
                    if(this.contractInfo.ownerMaintenanceFund>0){
                        this.contractInfo.Latestleaseate='1'
                        this.contractInfo.haveLatestleaseate='1'
                    }
                for(let i in data.contractnofeeList){
                   data.contractnofeeList[i].addTime=util.dateFormat(new Date(data.contractnofeeList[i].addTime))
                   data.contractnofeeList[i].everyYearEntrustBeginTime=util.dateFormat(new Date(data.contractnofeeList[i].everyYearEntrustBeginTime))
                   data.contractnofeeList[i].everyYearEntrustEndTime=util.dateFormat(new Date(data.contractnofeeList[i].everyYearEntrustEndTime))
                   data.contractnofeeList[i].noFeeBeginTime=util.dateFormat(new Date(data.contractnofeeList[i].noFeeBeginTime))
                   data.contractnofeeList[i].noFeeEndTime=util.dateFormat(new Date(data.contractnofeeList[i].noFeeEndTime))
                }
                let that = this
                that.propertyRightAddress = data.propertyRightAddress
                that.houseAddress = data.houseAddress

                that.ownerInfo.estateRoom_id = data.estateRoom_id
                that.ownerInfo.potentialResource_id = data.potentialResource_id
                
                //    业主性别
                that.ownerInfo.sex = data.ownerGender + ''
                //    业主手机号 
                that.ownerInfo.phone = that.findUserInfoByMobile(true, false,data.ownerMobile + '')
                that.ownerInfo.name = data.ownerName + ''
                that.ownerInfo.certificateCode = data.ownerCertificateCode
                that.ownerInfo.certificateType = that.setSelectObj(that.certificateTypeArr, data.ownerCertificateType)
                // 紧急联系人
                that.ownerInfo.emergencyContactName = data.emergencyContactPerson
                that.ownerInfo.emergencyContactPhone = data.emergencyContactPhone
                that.ownerInfo.email = data.emergencyContactEmail
                //    紧急联系地址
                that.ownerInfo.emergencyAddress = data.emergencyContactAddress
                // 照片
                if (data.photo) {
                    that.ownerInfo.yzFrontOfCard = data.photo.lesseeOrOwnerIdFront ? data.photo.lesseeOrOwnerIdFront : require('../../../assets/images/default_img_4.png')
                    that.ownerInfo.yzReverseOfCard = data.photo.lesseeOrOwnerIdBack ? data.photo.lesseeOrOwnerIdBack : require('../../../assets/images/default_img_5.png')
                    that.ownerInfo.yzHoldCard = data.photo.lesseeOrOwnerIdHand ? data.photo.lesseeOrOwnerIdHand : require('../../../assets/images/default_img_3.png')
                } else {
                    that.ownerInfo.yzFrontOfCard = require('../../../assets/images/default_img_4.png')
                    that.ownerInfo.yzReverseOfCard = require('../../../assets/images/default_img_5.png')
                    that.ownerInfo.yzHoldCard = require('../../../assets/images/default_img_3.png')
                }
                that.ownerInfo.receiver = data.payeeName
                // 所属银行
                that.contractInfo.bankType = that.setSelectObj(that.bankTypeArr, data.bank)
                //储蓄类型
                that.bankTypeInfo = that.getObjByTitle(that.savingsCertificateList,data.savingsCertificateTypeTitle)
                that.ownerInfo.bankChanel = data.subBank
                that.ownerInfo.bankBob = data.salesDepartmentNo
                that.ownerInfo.bankCard = data.bankCode
                that.ownerInfo.bankCardFront = data.photo.bankCardFront ? data.photo.bankCardFront : require('../../../assets/images/default_img_2.png')
                that.ownerInfo.bankCardReverse = data.photo.bankCardBack ? data.photo.bankCardBack : require('../../../assets/images/default_img_1.png')
                if (data.hasAgent == "true") {
                    that.proxyRadio = '1'
                    that.isChooseProxy = true
                    that.isChooseProxy = data.hasAgent
                    that.proxyInfo.name = data.agentName
                    that.proxyInfo.phone = data.agentMobile
                    that.proxyInfo.certificateType = that.setSelectObj(that.certificateTypeArr, data
                        .agentCertificateType)
                    that.proxyInfo.certificateCode = data.agentCertificateCode
                    if (data.photo) {
                        that.proxyInfo.wtrFrontOfCard = data.photo.togetherOrEntrustIdFront ? data.photo.togetherOrEntrustIdFront : require('../../../assets/images/default_img_4.png')
                        that.proxyInfo.wtrReverseOfCard = data.photo.togetherOrEntrustIdBack ? data.photo.togetherOrEntrustIdBack : require('../../../assets/images/default_img_5.png')
                        that.proxyInfo.entrustment = data.photo.entrustBook ? data.photo.entrustBook : require('../../../assets/images/default_img_6.png')
                        that.proxyInfo.holdEntrustment = data.photo.entrustBookHand ? data.photo.entrustBookHand : require('../../../assets/images/default_img_6.png')
                    } else {
                        that.proxyInfo.wtrFrontOfCard = require('../../../assets/images/default_img_4.png')
                        that.proxyInfo.wtrReverseOfCard = require('../../../assets/images/default_img_5.png')
                        that.proxyInfo.entrustment = require('../../../assets/images/default_img_6.png')
                        that.proxyInfo.holdEntrustment = require('../../../assets/images/default_img_6.png')
                    }
                }
                if (data.hasCoOwner == "true") {
                    that.coOwnerRadio = '1'
                    that.isChooseCoOwner = true
                    that.isChooseCoOwner = data.hasCoOwner
                    that.coOwner.name = data.coOwnerName
                    that.coOwner.phone = data.coOwnerMobile
                    that.coOwner.certificateType = that.setSelectObj(that.certificateTypeArr, data
                        .coOwnerCertificateType)
                    that.coOwner.IdCard = data.coOwnerCertificateCode
                    if (data.photo) {
                        // 共有权人图片
                        that.coOwner.commonOwnerFrontOfCard = data.photo.commonOwnerIdCardFront ? data.photo.commonOwnerIdCardFront:require('../../../assets/images/default_img_4.png')
                        that.coOwner.commonOwnerReverseOfCard = data.photo.commonOwnerIdCardBack ? data.photo.commonOwnerIdCardBack:require('../../../assets/images/default_img_5.png')
                    } else {
                        that.coOwner.commonOwnerFrontOfCard = require('../../../assets/images/default_img_4.png')
                        that.coOwner.commonOwnerReverseOfCard = require('../../../assets/images/default_img_5.png')
                    }
                }
                that.contractInfo.number = data.contractCode
                if(that.flagType==2){
                    //租约变更默认交房日期为上一次合同结束日期加一天
                    that.contractInfo.giveHouseTime = that.getNewDataDays(data.contractEndTime,1)
                } else {
                    that.contractInfo.giveHouseTime = data.giveHouseTime
                }
                if(that.flagType==2){
                    //租约变更续签默认装修开始日期
                    that.contractInfo.startDecorateTime = that.contractInfo.giveHouseTime
                } else {
                    that.contractInfo.startDecorateTime = data.renovationBeginTime
                }
                if(that.flagType==2){
                    //租约变更续签默认装修结束时间为开始时间
                    // that.contractInfo.endDecorateTime = that.contractInfo.giveHouseTime
                } else {
                    that.contractInfo.endDecorateTime = data.renovationEndTime
                }
                if(that.flagType==2){
                    //租约变更续签默认合同开始时间为交房时间
                    that.contractInfo.noFeeBeginTime = that.contractInfo.giveHouseTime
                } else {
                    that.contractInfo.noFeeBeginTime = data.contractBeginTime
                }
                if(that.flagType==2){
                    //租约变更续签默认合同结束时间为开始时间加上上一次合同的租期 根据租期自动算出来的
                    that.contractInfo.noFeeEndTime = that.getNewDataYears1(that.contractInfo.giveHouseTime,parseInt(data.contractYears))
                } else {
                    that.contractInfo.noFeeEndTime = data.contractEndTime
                }
                that.contractInfo.leaseTerm = data.contractYears + ''
                that.contractInfo.rent = data.rentMoney + ''
                that.contractInfo.deposit = data.depositMoney
                that.contractInfo.freeTimeType = that.setSelectObj(that.freeTimeTypeArr, data.noFeeType)
                that.contractInfo.paymentMethod = that.setSelectObj(that.paymentMethodArr, data.payType_id)
                that.contractInfo.ownershipType = that.setSelectObj(that.ownershipTypeArr, data.propertyRightType_id,
                    'id')
                that.ownerInfo.rightNumber = data.propertyRightCode
                that.ownerInfo.buildArea = data.builtUpArea + ''
                that.contractInfo.toWhereType = that.setSelectObj(that.toWhereTypeArr, data.orientation_id)
                that.contractInfo.heatingType = that.setSelectObj(that.heatingTypeArr, data.heatingMode)
                that.contractInfo.mortgageType = that.setSelectObj(that.mortgageTypeArr, data.mortgageSituation)
                that.contractInfo.province.id=data.provinceId
                that.contractInfo.province.name=data.province
                that.changeProvince()
                that.contractInfo.city.id=data.cityId
                that.contractInfo.city.name=data.city
                that.contractInfo.city.code=data.cityCode

                //    是否装修
                that.contractInfo.isRenovation = data.isRenovation
                
                that.interbankId=data.interBankId
                if(!that.interbankId){
                    that.warnSubBank='输入支行'
                    that.notFindSubBank=true
                }
                // that.contractInfo.enclosurePropertyPicList = data.enclosurePropertyPicList
                // that.contractInfo.outLinecontratPicList = data.outLinecontratPicList
                if (data.outLinecontratPicList && data.outLinecontratPicList.length > 0){
                    that.contractInfo.outLinecontratPicList = [];
                    for (let j = 0; j < data.outLinecontratPicList.length; j++) {
                        that.contractInfo.outLinecontratPicList.push({
                            content:  data.outLinecontratPicList[j].path,
                            url:  data.outLinecontratPicList[j].path,
                            path:  data.outLinecontratPicList[j].path
                        })
                    }
                }
                if (data.enclosurePropertyPicList && data.enclosurePropertyPicList.length > 0){
                    that.contractInfo.enclosurePropertyPicList = [];
                    for (let j = 0; j < data.enclosurePropertyPicList.length; j++) {
                        that.contractInfo.enclosurePropertyPicList.push({
                            content:  data.enclosurePropertyPicList[j].path,
                            path:  data.enclosurePropertyPicList[j].path,
                            url:  data.enclosurePropertyPicList[j].path
                        })
                    }
                }

                // 添加标签
                that.contractLabel = that.setLables(data.labelIds)
                that.contractInfo.explanationDescribe = data.supplementaryRemark
                that.contractInfo.signatory = that.setSelectObj(that.signatoryArr, data.signStaff_id, "id")
                if (data.partnerStaff_id && data.partnerStaff_id != "") {
                  console.log('接口')
                    that.contractInfo.partner = that.setSelectObj(that.partnerArr, data.partnerStaff_id)
                    that.contractInfo.cooperationType = that.setSelectObj(that.cooperationTypeArr, data.partnerRatio_id,
                        "id")
                }
                //    线上签约还是线下
                that.isOnlineSign = data.signType == "1"
                that.isUplineSign = data.signType == "2"
                //    签约人角色
                that.contractInfo.signedType = that.setSelectObj(that.ownerTypeArr, data.signerRole)
                // 推荐人
                that.referrerText=data.recommendUserMobile
                that.initReferrer()
                /*that.contractInfo.partnershipProportion = that.setSelectObj(that.partnershipProportionArr, data
                    .recommendUser_id, "id")*/
                //渠道费
                if(data.channelMoney){
                  that.contractInfo.channelMoney=data.channelMoney
                }
                this.freeRentArr=data.contractnofeeList

            },
            getLables() {
                if(this.contractLabel == undefined){
                    this.contractLabel = []
                }
                let result = []

                let fifterArr = this.contractLabel.filter((item) => {
                    return item.isChoose
                })
                if (fifterArr != []) {
                    fifterArr.forEach((item) => {
                        result.push(item.id)
                    })
                }
                return result
            },
            setLables(lables) {
                let target = this.contractLabel
                lables = lables || ''
                lables = lables.split(',')
                for (let i = 0; i < target.length; i++) {
                    for(let j = 0; j< lables.length; j++){
                        if (target[i].id == lables[j]) {
                            this.$set(target[i],'isChoose',true)
                        }
                    }

                }
                return target
            },
        //重新计算费用周期
        computeBills(bills){
           let index=0
           for(let i in bills){
                  for(let j=index;j<this.costTimeLimit.length;j++){
                   if(bills[i].type!='otherFees'){
                    if(this.contractInfo.paymentMethod.value==0){  //月付
                        bills[i].costBeginDate=this.costTimeLimit[j].beginDate
                        bills[i].costEndDate=(i==0||i%12==0)?this.costTimeLimit[j+1].endDate:this.costTimeLimit[j].endDate
                        bills[i].collectionDate=i==0?util.dateFormat(util.dateAddDay(this.contractInfo.giveHouseTime,3)):bills[i].costBeginDate
                        bills[i].range=bills[i].costBeginDate+'至'+bills[i].costEndDate
                        if(i==0||i%12==0){
                           index+=2
                        }else{
                           index++
                        }
                        break
                    }
                    if(this.contractInfo.paymentMethod.value==1){  //季付
                        bills[i].costBeginDate=this.costTimeLimit[j].beginDate
                        bills[i].costEndDate=(i==0||i%4==0)?this.costTimeLimit[j+1].endDate:this.costTimeLimit[j].endDate
                        bills[i].collectionDate=i==0?util.dateFormat(util.dateAddDay(this.contractInfo.giveHouseTime,3)):bills[i].costBeginDate
                        bills[i].range=bills[i].costBeginDate+'至'+bills[i].costEndDate
                        if(i==0||i%4==0){
                           index+=2
                        }else{
                           index++
                        }
                        break
                    }
                    if(this.contractInfo.paymentMethod.value==2){  //半年付
                        bills[i].costBeginDate=this.costTimeLimit[j].beginDate
                        bills[i].costEndDate=(i==0||i%2==0)?this.costTimeLimit[j+1].endDate:this.costTimeLimit[j].endDate
                        bills[i].collectionDate=i==0?util.dateFormat(util.dateAddDay(this.contractInfo.giveHouseTime,3)):bills[i].costBeginDate
                        bills[i].range=bills[i].costBeginDate+'至'+bills[i].costEndDate
                        if(i==0||i%2==0){
                           index+=2
                        }else{
                           index++
                        }
                        break
                    }
                    if(this.contractInfo.paymentMethod.value==3){  //年付
                        bills[i].costBeginDate=this.costTimeLimit[j].beginDate
                        bills[i].costEndDate=(i==0||i%1==0)?this.costTimeLimit[j+1].endDate:this.costTimeLimit[j].endDate
                        bills[i].collectionDate=i==0?util.dateFormat(util.dateAddDay(this.contractInfo.giveHouseTime,3)):bills[i].costBeginDate
                        bills[i].range=bills[i].costBeginDate+'至'+bills[i].costEndDate
                        if(i==0||i%1==0){
                           index+=2
                        }else{
                           index++
                        }
                        break
                    }
                    if(this.contractInfo.paymentMethod.value==4){  //一次性付请
                        bills[i].costBeginDate=this.costTimeLimit[j].beginDate
                        bills[i].costEndDate=this.costTimeLimit[this.costTimeLimit.length-1]
                        bills[i].collectionDate=util.dateFormat(util.dateAddDay(this.contractInfo.giveHouseTime,3))
                        bills[i].range=bills[i].costBeginDate+'至'+bills[i].costEndDate
                        index++
                        break
                    }
                   }
                  }
           }

            for(let i=bills.length-1;i>0;i--){
               if(i==bills.length-1){
                    bills[i].costEndDate=this.costTimeLimit[this.costTimeLimit.length-2].endDate
                    bills[i].range=bills[i].costBeginDate+'至'+bills[i].costEndDate
               }
               if(bills[i].type=='otherFees'&&bills[i-1].type=='otherFees'){
                bills[i-1].costEndDate=util.dateFormat(util.dateAddDay(bills[i].costBeginDate,-1))
                bills[i-1].range=bills[i-1].costBeginDate+'至'+bills[i-1].costEndDate
               }
            }
           return bills
        },
        getOtherFees(){
            let that=this
          that.contractInfo.otherFees=[]
            if(that.contractInfo.Latestleaseate=='1'&&that.contractInfo.haveLatestleaseate=='1'){
                var json={}
                json.billCollectionPaymentType='收'
                json.payTypeId='3'    //年付
                json.payUnitType='2'  // 300/每年
                json.costtype='1'  //服务费
                json.costtypeVal='服务费',
                json.totalamount=Number(that.contractInfo.ownerMaintenanceFund)
                json.count=that.contractInfo.contractYears
                json.identity='owner'
                json.describe=''
                json.typeFlag='1'
                json.noFeeType=that.contractInfo.leaseTerm
                json.oldNoFeeNum= 0
                json.otherPayTypeId='3'
                that.contractInfo.otherFees.push(json)
            }else{
                that.contractInfo.otherFees=[]
            }
        },
            save() {
                let that = this;
                // if (!this.checkCanSave()) {
                //     return
                // }
                if(!that.saveFlag){
                    return
                }
                if(!that.canReallySave()){
                    return
                }
                that.saveFlag = false
                that.getOtherFees()
                let initData = that.requestParams();
                if(that.contractInfo.freeTimeType.value == config.FREE_PAY_OUT){
                        initData.bills=that.computeBills(initData.bills)
                }
                if(initData.recommendUser_id==0){
                    initData.recommendUser_id=''
                }
                console.log(initData)
                saveOrUpdateOwnercontract(initData).then(function (response) {
                    if(response.data.code!=0){
                        that.saveFlag = true
                    }
                    responseUtil.dealResponse(that, response, () => {
                        responseUtil.alertMsg(that,'操作成功')
                        localStorage.setItem('type','2');
                        //统一跳转到合同列表
                        that.$router.push({
                            name : 'contractList',
                            query : {
                                currentLabel: '1',
                                type:'2'
                            }
                        })
                    });
                });
            },
        setRepair(){
            if(this.contractInfo.haveLatestleaseate=='0'){
                this.contractInfo.ownerMaintenanceFund='0'
            }else{
                this.contractInfo.ownerMaintenanceFund=this.newOwnerMaintenanceFund
            }
        }
        }
    };
</script>

<style scoped lang="less">
    .nav-bar {
        background-color: white;
        border: 0;
    }
    input:disabled{
        background-color: #fff;
        opacity: 1;
        color: #ddd;
    }

    .nopadding{
        padding: 0;
        flex: auto;
    }

    .headbar {
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 40px;
        width: 100%;
        font-size: 15px;
        font-weight: 600;
        padding-top: 10px;
    }

    .headbar-fixed {
        background-color: white;
        position: fixed;
        top: 50px;
        z-index: 99;
    }

    .headbar-redline {
        height: 4px;
        width: 20px;
        border-radius: 3px;
        background-color: #ff5d3b;
        margin: 5px 0 0 20px;
    }

    .headbar-graytick {
        display: inline-block;
        background: url("../../../assets/images/grayTick.png");
        width: 18px;
        height: 18px;
        background-size: 18px;
        margin-bottom: -3px;
    }

    .headbar-greentick {
        display: inline-block;
        background: url("../../../assets/images/GrassgreenTick.png");
        width: 18px;
        height: 18px;
        background-size: 18px;
        margin-bottom: -3px;
    }

    .contentpart {
        margin-top: 80px;
    }

    .addressInput{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0;
        flex: auto;
    }

    .part {
        margin: 15px;
    }

    .other-part {
        margin: 0 15px 50px 15px;
    }

    .other-part-add {
        top: 42px;
    }

    .part-subtitle {
        color: #ff5d3b;
        font-size: 12px;
        font-weight: 600;
        position: relative;
        top: 12px;
    }

    .proxy-info {
        color: #ff5d3b;
        font-size: 12px;
        font-weight: 600;
        display: flex;
        align-items: center;
        margin-top: 20px;
    }

    .select-icon-blue {
        width: 8px;
        height: 8px;
        border: 1px solid lightblue;
        margin-left: 3px;
    }

    .blue-Active {
        background-color: blue;
    }

    .chooseSelectIcon {
        display: inline-block;
        width: 6px;
        height: 6px;
        border: 2px solid blue;
        position: relative;
        top: 12px;
    }

    .part-inputpart {
        display: flex;
        flex-direction: column;
        white-space: nowrap;
        overflow: hidden;
        font-size: 15px;
    }

    .part-inputpart-houseAddress {
        margin: 5px 15px 20px;
    }

    .part-horizontalLine {
        width: 100%;
        height: 2px;
        background-color: #ededed;
        margin-top: 50px;
    }

    .part-button {
        height: 45px;
        border-radius: 10px;
        background-color: #ededed;
        color: #ffffff;
        text-align: center;
        line-height: 45px;
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 50px;
    }

    .part-button-enabled {
        background-image: linear-gradient(to right, #ffc274, #ff5d3b);
    }

    .part-hyperlinks {
        float: right;
        color: #999999;
        font-size: 12px;
        margin-top: 10px;
    }

    .part-inputpart div:last-child {
        border: none;
    }

    .part-inputpart-row {
        display: flex;
        flex-direction: row;
        border-radius: 8px;
        align-items: center;
        height: 45px;
        background-color: #ffffff;
        margin-top: 15px;
    }

    .part-inputpart-row-1 {
        display: flex;
        flex-direction: row;
        border-radius: 8px;
        align-items: center;
        height: 45px;
        background-color: #ffffff;
        margin-top: 15px;
    }

    .part-inputpart-row-short {
        width: 48%;
        float: left;
    }

    .part-inputpart-row-short-right {
        float: right;
    }

    .part-inputpart-row-short-graytext {
        color: #d8d8d8;
    }

    .part-inputpart-row-header {
        font-weight: 600;
    }

    .part-inputpart-row-sex {
        margin-top: 26px;
    }

    .radio_group {
        padding-left: 25px;
    }

    .part-inputpart-row-startPeriod {
        vertical-align: middle;
        flex: auto;
    }

    .part-inputpart-row-endPeriod {
        padding-left: 10px;
        vertical-align: middle;
        flex: auto;
    }

    .part-inputpart-man {
        margin-left: 54px;
        margin-right: 18px;
    }

    .part-inputpart-woman {
        margin-left: 18px;
    }

    .part-inputpart-yes {
        margin-left: 120px;
        margin-right: 18px;
    }

    .part-inputpart-isSupport {
        margin-left: 80px;
        margin-right: 18px;
    }

    .part-inputpart-cardpic {
        margin-top: 15px;
        border-radius: 8px;
        background-color: white;
    }

    .part-inputpart-cardpic-row {
        margin: 0;
    }

    .part-inputpart-cardpic-module {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .part-inputpart-cardpic-module-1 {
        display: flex;
        padding-top: 20px;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
    }
    /*银行卡左对齐*/
    .part-inputpart-cardpic-module-2 {
        display: -webkit-box;
        display: -webkit-flex;
        /* display: flex; */
        padding-top: 0.53333rem;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: center;
        /* -webkit-justify-content: center; */
        /* justify-content: center; */
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .part-cardpic-module {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .part-inputpart-cardpic-pic {
        width: 85.6px;
        height: 54px;
        border-radius: 5px;
        margin: 0 15px;
        background-color: #e1eaec;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;

        }
    }

    .part-inputpart-cardpic-button {
        display: inline-block;
        background: url("../../../assets/images/grayAdd.png") center center no-repeat;
        width: 85.6px;
        height: 54px;
        border-radius: 5px;
        background-size: 30px;
        background-color: #fafafa;
        margin: 0 15px;
    }

    .part-inputpart-cardpic-text {
        font-size: 12px;
        color: #d8d8d8;
        margin: 7px 0 13px;
    }

    .part-inputpart-textarea {
        display: flex;
        flex-direction: column;
        margin-top: 15px;
        background-color: white;
        padding-top: 6px;
        border-radius: 8px;
    }

    .part-inputpart-textarea textarea {
        height: 100px;
        background-color: #fafafa;
        border-radius: 8px;
        padding: 10px;
        margin: 10px;
        border: none;
        resize: none;
    }

    .part-inputpart-textarea textarea::-webkit-input-placeholder {
        color: #d8d8d8;
    }

    input {
        border: 0;
        width: 100%;
    }

    input::-webkit-input-placeholder {
        color: #d8d8d8;
    }

    .otherExpenses-inputpart-row input::-webkit-input-placeholder {
        color: #d8d8d8;
    }

    .part-uploadpic-content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .part-uploadpic-module {
        margin: 10px 25px 10px 10px;
    }

    .part-uploadpic-text {
        font-size: 15px;
        margin-left: 24px;
    }

    .part-uploadpic-module-pic {
        width: 80px;
        height: 80px;
        border-radius: 8px;
    }

    .part-uploadpic-van-uploader {
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: 10px;
    }

    .part-inputpart-dropDownList {
        height: 23%;
        /*垂直方向滚动*/
        overflow-y: scroll;
        overflow: auto;
        width: 92.5%;
        position: absolute;
        z-index: 99;
    }

    .part-inputpart-dropDownList-option {
        padding: 0;
        margin: 0;
        text-align: center;
        line-height: 30px;
        background-color: #f5f5f5;
        font-size: 12px;
        border-bottom: 1px white solid;
        color: #999999;
    }

    .part-other-dropDownList-option {
        width: 92%;
    }

    .part-inputpart-dropDownList-option-selected {
        color: #ff5d3b;
    }

    .part-inputpart-row-enablenon {
        margin-left: 26px;
    }

    .text-heating {
        margin-left: 26px;
        font-weight: 600;
        font-size: 15px;
    }

    .divid-heating {
        display: flex;
        align-items: center;
        font-size: 15px;
        font-weight: 600;
        margin-right: 5px;
    }

    .text-contract {
        min-width: 1.5rem;
        height: 0.6rem;
        line-height: 0.6rem;
        border-radius: 0.1rem;
        background-color: #F5F9FE;
        color: #D8E8FA;
        font-size: 0.26667rem;
        text-align: center;
        font-family: PingFangSC-Regular;
        padding-left: 0.2rem;
        padding-right: 0.2rem;
    }

    .text-contractActive {
        min-width: 1.5rem;
        height: 0.6rem;
        line-height: 0.6rem;
        border-radius: 0.1rem;
        background-color: #DDEBFB;
        color: #6AA7EE;
        font-size: 10px;
        text-align: center;
        font-family: PingFangSC-Regular;
        padding-left: 0.2rem;
        padding-right: 0.2rem;
    }

    .part-inputpart-column-enablenon {
        margin-left: 10px;
    }
    .spanStyle{
        font-weight: normal;
        color:rgba(199, 199, 199, 1)
    }

    .otherExpenses-row-enablenon {
        margin-left: 20px;
    }

    .part-inputpart-row-graytext {
        color: #d8d8d8;
        flex: auto;
    }

    .part-inputpart-row-normaltext {
        color: black;
        /*width: 100%;*/
        flex: auto;
    }

    /*不可选择*/
    .part-inputpart-row-noChoice {
        color: #c8c9cc;
        /*width: 100%;*/
        flex: auto;
    }

    .part-inputpart-row-redtext {
        flex:auto ;
        color: #ff5d3b;
    }
    .part-inputpart-row-greentext {
        color: #ff5d3b;
        margin:0 30px;
    }
    .part-inputpart-row-bluetext {
        color: #3590f6;
    }

    .part-inputpart-other-redtext {
        color: #ff5d3b;
    }

    .part-inputpart-row-housetype input {
        width: 18px;
        margin-left: 5px;
    }

    .part-inputpart-row-right {
        float: right;
        margin-right: 5px;
        margin-top:-5px;
    }

    .part-inputpart-row-right-rightArrow {
        width: 8px;
    }

    .part-inputpart-row-right-downArrow {
        width: 9px;
    }

    .part-inputpart-row-right-upArrow {
        width: 9px;
        transform: rotateX(180deg);
    }

    .part-inputpart-row-right-timepick {
        width: 13px;
        height: 13px;
        margin-top: 2px;
    }

    .content-have {
        padding: 3px;
        margin: 0 10px;
        border-radius: 50%;
        background-color: #ff5d3b;
    }

    .content-none {
        padding: 3px;
        margin: 0 10px;
        border-radius: 50%;
        background-color: #c7c7c7;
    }

    .content-divide {
        font-size: 12px;
        color: #efefef;
        margin: 0 10px;
    }

    .content-divide-position {
        position: relative;
        bottom: 2px;
    }

    .content-bigdivide {
        width: 2px;
        height: 20px;
        background-color: #efefef;
        margin: 0px 40px 10px;
    }

    .redsmallLabel {
        width: 32px;
        height: 14px;
        border-radius: 3px;
        background-color: #fcded7;
        color: #ff5d3b;
        text-align: center;
        font-size: 11px;
        line-height: 14px;
        margin: 8px;
    }

    .otherExpenses-inputpart {
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        white-space: nowrap;
        overflow: hidden;
        font-size: 15px;
        margin-top: 10px;
    }

    .otherExpenses-inputpart-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 45px;
        background-color: #ffffff;
        margin-top: 2px;
    }

    .otherExpenses-inputpart-column {
        display: flex;
        flex-direction: column;
        height: 45px;
        padding: 20px 0;
        justify-content: space-between;
        background-color: #ffffff;
        margin-top: 2px;
    }

    .otherExpenses-inputpart-row input {
        border: 0;
        width: 100%;
    }
    .radio_span {
        font-size: 15px;
        color: rgba(153, 153, 153, 1);
        padding: 10px;
        padding-right: 0;

    }
    .isChecked {
        color: tomato !important;
    }
    .preview-cover {
        position: absolute;
        box-sizing: border-box;
        bottom: 0;
        width: 100%;
        padding: 4px;
        color: #fff;
        font-size: 12px;
        text-align: center;
        background: rgba(0, 0, 0, 0.3);
    }
    .van-uploader__wrapper{
        padding-top: 0.1rem;
    }

    .van-cell::after {
        border-bottom: none;
    }
    .van-cell{
        //display:inline-block !important;
        //width:30%;
    }
    .mianzuqi{
        display:inline-block !important;
        width:30%;
        flex: auto;
    }
    .nopay-month{
        position:relative;
        top:-7px;
        margin:0 20px;
        font-size:15px
    }

</style>
