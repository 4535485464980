<template>
  <div class="vue-dropdown default-theme" v-show-extend="show">
    <div class="search-module clearfix" v-if="length"  @click.prevent.stop ="fouce">
      <input class="search-text" @keyup='search($event)' :placeholder="placeholder"/>
      <span class="glyphicon glyphicon-search search-icon"></span>
    </div>
    <ul class="list-module" v-if="length">
      <li v-for="(item,index) in datalist" @click="appClick(item)" :key="index">
        <span class="list-item-text">{{item.title}}</span>
      </li>
    </ul>
    <div class="tip__nodata" v-if="!datalist.length">{{nodatatext}}</div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        _datalist: this.itemlist.concat(),
        datalist: this.itemlist.concat(),
        length: this.itemlist.length
      }
    },
    props: {
      'show': { //用于外部控制组件的显示/隐藏
        type: Boolean,
        default: true
      },
      'itemlist': Array,
      'placeholder': String,
      'nodatatext': String
    },
    directives: {
      'show-extend': function (el, binding, vnode) { //bind和 update钩子
        let value = binding.value,
          searchInput = null;
        if (value) {
          el.style.display = 'block';
        } else { //隐藏后，恢复初始状态
          el.style.display = 'none';
          searchInput = el.querySelector(".search-text");
          searchInput.value = '';
          vnode.context.datalist = vnode.context.itemlist; //还原渲染数据
        }
      }
    },
    methods: {
      fouce(){
        this.show  = true
      },
      appClick: function (data) {
        this.$emit('item-click', data);
      },
      search: function (e) {
        let vm = this,
          searchvalue = e.currentTarget.value;
          vm.datalist = vm.$data._datalist.filter(function (item) {
          return item.staffName.indexOf(searchvalue) != -1;
        });
      }
    },
    mounted: function () {

    }
  }
</script>

<style lang="less" scoped>
  .vue-dropdown.default-theme {
    // position: absolute;
    // left: 15%;
    display: none;
    width: 100%;
    margin: 0 auto;
    // margin-top: 1em;
    height: 100%;
    padding: 1em;
    z-index: 10;
    box-sizing: border-box;
    &._self-show {
      display: block !important;
    }

    .search-module {
      position: relative;

      .search-text {
        width: 80%;
        height: 30px;
        margin: 0 auto;
        border-radius: 0.5em;
        box-shadow: none;
        border: 1px solid #ccc;
        text-indent: 2em;
        &:focus {
          border-color: #2198f2;
        }
      }

      .search-icon {
        position: absolute;
        top: 24%;
        right: 0.5em;
        color: #aaa;
      }

    }

    .list-module {
      max-height: 200px;
      overflow-y: auto;

      li {
        &._self-hide {
          display: none;
        }

        margin-top: 0.5em;
        padding: 0.5em;

        &:hover {
          cursor: pointer;
          color: #fff;
          background: #00a0e9;
        }
      }
    }
  }

  .tip__nodata {
    font-size: 12px;
    margin-top: 1em;
  }
</style>